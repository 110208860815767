import React from 'react'

export default function Confirmation() {
  return (
    <div className='container mx-auto py-16 px-6 overflow-auto'>

      <div className='flex flex-col gap-6 items-center justify-center mb-[50px]'>

          <h3 className=' text-[#151618] text-center  text-4xl font-bold leading-[48px]'>Thank you for your booking with PlanMyTripz</h3>
          <div className='text-center'>
              <p className='text-[#151618] text-center font-normal text-[24px]  '>Your payment <b>AUD $3,500</b> was successful!</p>
              <p className='text-[#151618] text-center font-normal  text-[20px]  '>You will receive confirmations from individual tour operators for all your bookings.</p>
          </div>
      </div>



      <div className='overflow-x-auto border border-[#D6D6D8] rounded-xl'>

      <table className='w-full'>
        <thead>
          <tr>
            <th>
                <div className='flex flex-col gap-4 items-center justify-center border-r border-[#D6D6D8] py-4 px-6'>
                  <p className='text-[#9837F1] font-semibold text-[18px]'>Sydney</p>
                  <p className='flex items-center gap-2 font-normal '><img src='assets/front/calender.svg' /> 23 Jun - 25 Jun</p>
                  <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><img src='assets/front/user.svg' /> 2 pax</p>
              </div>
            </th>
            <th>
                <div className='flex flex-col gap-4 items-center justify-center border-r border-[#D6D6D8] py-4 px-6'>
                  <p className='text-[#9837F1] font-semibold text-[18px]'>Sydney</p>
                  <p className='flex items-center gap-2 font-normal '><img src='assets/front/calender.svg' /> 23 Jun - 25 Jun</p>
                  <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><img src='assets/front/user.svg' /> 2 pax</p>
              </div>
            </th>
            <th>
                <div className='flex flex-col gap-4 items-center justify-center border-r border-[#D6D6D8] py-4 px-6'>
                  <p className='text-[#9837F1] font-semibold text-[18px]'>Sydney</p>
                  <p className='flex items-center gap-2 font-normal '><img src='assets/front/calender.svg' /> 23 Jun - 25 Jun</p>
                  <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><img src='assets/front/user.svg' /> 2 pax</p>
              </div>
            </th>
            <th>
                <div className='flex flex-col gap-4 items-center justify-center border-r border-[#D6D6D8] py-4 px-6'>
                  <p className='text-[#9837F1] font-semibold text-[18px]'>Sydney</p>
                  <p className='flex items-center gap-2 font-normal '><img src='assets/front/calender.svg' /> 23 Jun - 25 Jun</p>
                  <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><img src='assets/front/user.svg' /> 2 pax</p>
              </div>
            </th>
            <th>
                <div className='flex flex-col gap-4 items-center justify-center  py-4 px-6'>
                  <p className='text-[#9837F1] font-semibold text-[18px]'>Sydney</p>
                  <p className='flex items-center gap-2 font-normal '><img src='assets/front/calender.svg' /> 23 Jun - 25 Jun</p>
                  <p className='flex items-center gap-2 font-normal ext-[#9837F1]'><img src='assets/front/user.svg' /> 2 pax</p>
              </div>
            </th>

          </tr>
        </thead>
        <tbody className='border-t border-[#D6D6D8]'>
          <tr>
            <td className='py-4 border-r border-[#D6D6D8]'>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>

                <div className='flex items-center justify-center mt-6 mb-4'>
                    <button className='border border-[#9837F1] font-semibold px-4 rounded-xl text-[#9837F1] py-2'>Add to Google Calendar</button>
                </div>
            </td>
            <td className='py-4 border-r border-[#D6D6D8]'>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>

                <div className='flex items-center justify-center mt-6 mb-4'>
                    <button className='border border-[#9837F1] font-semibold px-4 rounded-xl text-[#9837F1] py-2'>Add to Google Calendar</button>
                </div>
            </td>
            <td className='py-4 border-r border-[#D6D6D8]'>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>

                <div className='flex items-center justify-center mt-6 mb-4'>
                    <button className='border border-[#9837F1] font-semibold px-4 rounded-xl text-[#9837F1] py-2'>Add to Google Calendar</button>
                </div>
            </td>
            <td className='py-4 border-r border-[#D6D6D8]'>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>

                <div className='flex items-center justify-center mt-6 mb-4'>
                    <button className='border border-[#9837F1] font-semibold px-4 rounded-xl text-[#9837F1] py-2'>Add to Google Calendar</button>
                </div>
            </td>
            <td className='py-4 '>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>
                <div className='p-3 w-3/4 mx-auto'>
                    <img src='assets/front/image.png' className='h-24 object-cover w-full rounded-xl' />
                    <div className='mt-2'>
                        <h3 className='font-semibold text-[18px]'>Name of activity</h3>
                        <p className='flex items-center gap-2 text-[16px] font-regular '><img src='assets/front/calender.svg' className='h-4' /> 23 Jun - 25 Jun</p>
                    </div>
                </div>

                <div className='flex items-center justify-center mt-6 mb-4'>
                    <button className='border border-[#9837F1] font-semibold px-4 rounded-xl text-[#9837F1] py-2'>Add to Google Calendar</button>
                </div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      
    </div>
  )
}
