// src/components/DynamicPricingSection.js
import React from 'react';
import ReactECharts from 'echarts-for-react';

const DynamicPricingSection = () => {
    const option = {
        tooltip: { trigger: 'axis' },
        legend: { data: ['Actual', 'Forecast', 'Breakeven', 'Max Capacity'] },
        xAxis: { type: 'category', data: ['Jan 5', 'Jan 10', 'Jan 15', 'Jan 20', 'Jan 25'] },
        yAxis: { type: 'value' },
        series: [
            { name: 'Actual', type: 'line', data: [200, 240, 280, 220, 300] },
            { name: 'Forecast', type: 'line', data: [180, 220, 260, 200, 280] },
            { name: 'Breakeven', type: 'line', data: [160, 200, 240, 180, 260] },
            { name: 'Max Capacity', type: 'line', data: [300, 320, 340, 300, 350] },
        ],
    };

    return <>

<div className='border-b px-4 py-4 border-gray-200'>

<h2 className="text-[#212121] font-semibold text-[16px]">Data-Driven Dynamic Pricing</h2>

</div>


    
     <ReactECharts option={option} style={{ height: 400 }} />
    </> ;
};

export default DynamicPricingSection;
