import React, { useState } from "react";
import Layout from "../../components/global/layout";
import {
  HiOutlineArrowDown,
  HiOutlinePresentationChartBar,
} from "react-icons/hi";
import DateRangePicker from "../../components/common/DateRangePicker";
import { AiOutlineDownload } from "react-icons/ai";
import Badge from "../../components/Badge";
import { IoIosTrendingDown, IoIosTrendingUp } from "react-icons/io";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import Select from "react-select";
import { colourStyles } from "../../utils/constants";
import PieCircleChart from "./GraphCharts/PieCircleChart";
import VerticalBarGraph from "./GraphCharts/VerticalBarGraph";
import HorizontalBarGraph from "./GraphCharts/HorizontalBarGraph";
import SelectDropdown from "../../components/common/SelectDropdown";
import LineCurveTwobarGraph from "./GraphCharts/LineCurveTwobarGraph";
import { BiLike } from "react-icons/bi";
import datepipeModel from "../../models/datepipemodel";

const MarketingInsights = () => {
  const options = [
    { id: "Line", name: "Line Chart" },
    { id: "Bar", name: "Bar Chart" },
  ];
  const months = [
    { id: "month", name: "January" },
    { id: "month", name: "February" },
  ];
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    sortBy: "dateCreated desc",
    search: "",
    posId: "",
    status: "",
    productCode: "",
    category: [],
    travelStartDate: "",
    travelEndDate: "",
    reseller: "",
    third_party_delete: "",
    startDate: datepipeModel.datetostring(new Date()),
    endDate: datepipeModel.datetostring(new Date()),
    compare: false,
    compareStart: "",
    compareEnd: "",
  });
  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    };

    if (
      datepipeModel.datetostring(e.startDate) ===
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      setFilter({ ...filters, ...f });
      dataFilter({ ...f });
    }
  };
  return (
    <div>
      <Layout>
        <div className="flex items-center justify-between mb-6">
          <div className="flex flex-col gap-1">
            <h4 className="text-2xl font-semibold text-[#3C3E49]">Marketing</h4>
            <p className="text-sm font-normal text-[#75757A]">
              Here you can see all about your product statistics
            </p>
          </div>
          <div className="flex gap-4">
            <button
              type="button"
              title=""
              className="!px-4 text-typo text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-extra_large shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3]disabled:bg-[#F3F2F5]!disabled:cursor-not-allowed cursor-pointer"
            >
              <HiOutlinePresentationChartBar className="text-typo text-xl" />
              View Insights
            </button>
            <button
              type="button"
              className="!px-4 text-sm font-normal text-primary h-11 group flex items-center justify-center gap-2 bg-white rounded-extra_large shadow-btn hover:bg-primary hover:text-white border border-primary transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
            >
              <BiLike className="text-xl text-primary group-hover:text-white" />
              View Recommendation
            </button>
          </div>
        </div>
        <div className="grow mb-7 flex flex-wrap items-center justify-end gap-3">
          <DateRangePicker
            disabled={filters.yearType === "calendar"}
            title={
              filters.yearType === "calendar"
                ? ""
                : "Change Data by Calendar Year to change dates"
            }
            value={{
              startDate: filters.startDate,
              endDate: filters.endDate,
              compare: filters.compare,
              compareStart: filters.compareStart,
              compareEnd: filters.compareEnd,
            }}
            isCompare={true}
            onChange={(e) => onRangeChange(e)}
            fullWidth={false}
          />
          <div class="w-full max-w-[300px] !p-1 bg-[#F1F3F9] border border-gray-200 rounded-extra_large flex items-center gap-2">
            <label class="flex-1 mb-0 flex items-center justify-center gap-2 rounded-large text-sm font-medium py-2 px-3 focus:outline-none text-[#1D2433A6]">
              Financial year
            </label>
            <label class="flex-1 mb-0 flex items-center justify-center gap-2 rounded-large text-sm font-medium py-2 px-3 focus:outline-none text-typo bg-white cursor-pointer shadow-sm border-2 border-primary">
              Calendar year
            </label>
          </div>
          <DateRangePicker
            disabled={filters.yearType === "calendar"}
            title={
              filters.yearType === "calendar"
                ? ""
                : "Change Data by Calendar Year to change dates"
            }
            value={{
              startDate: filters.startDate,
              endDate: filters.endDate,
              compare: filters.compare,
              compareStart: filters.compareStart,
              compareEnd: filters.compareEnd,
            }}
            isCompare={true}
            onChange={(e) => onRangeChange(e)}
            fullWidth={false}
          />
          <button
            type="button"
            className="relative cursor-default rounded-large bg-[#fff] !px-4 text-sm text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
          >
            <AiOutlineDownload className="text-typo text-xl" />
            Export PDF
          </button>
        </div>
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 mb-6">
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="New Users" />
            <div className="flex justify-between gap-4 items-center">
              <div>
                <h2 className="text-typo text-2xl font-medium mb-2.5">
                  316,796
                </h2>
                <div className="flex items-center">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  <span className="text-[#E0173C] text-xs font-medium block ml-1">
                    2.11%
                  </span>
                  <p className="text-sm text-[#6B7280] font-medium ml-2">
                    vs last month
                  </p>
                </div>
              </div>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Sessions" />
            <div className="flex justify-between gap-4 items-center">
              <div>
                <h2 className="text-typo text-2xl font-medium mb-2.5">
                  443,117
                </h2>
                <div className="flex items-center">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  <span className="text-[#E0173C] text-xs font-medium block ml-1">
                    2.11%
                  </span>
                  <p className="text-sm text-[#6B7280] font-medium ml-2">
                    vs last month
                  </p>
                </div>
              </div>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Users" />
            <div className="flex justify-between gap-4 items-center">
              <div>
                <h2 className="text-typo text-2xl font-medium mb-2.5">
                  325,436
                </h2>
                <div className="flex items-center">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  <span className="text-[#E0173C] text-xs font-medium block ml-1">
                    2.11%
                  </span>
                  <p className="text-sm text-[#6B7280] font-medium ml-2">
                    vs last month
                  </p>
                </div>
              </div>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Session Duration" />
            <div className="flex justify-between gap-4 items-center">
              <div>
                <h2 className="text-typo text-2xl font-medium mb-2.5">
                  2h 53s
                </h2>
                <div className="flex items-center">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  <span className="text-[#E0173C] text-xs font-medium block ml-1">
                    2.11%
                  </span>
                  <p className="text-sm text-[#6B7280] font-medium ml-2">
                    vs last month
                  </p>
                </div>
              </div>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Bounce Rate" />
            <div className="flex justify-between gap-4 items-center">
              <div>
                <h2 className="text-typo text-2xl font-medium mb-2.5">
                  48.57%
                </h2>
                <div className="flex items-center">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  <span className="text-[#E0173C] text-xs font-medium block ml-1">
                    2.11%
                  </span>
                  <p className="text-sm text-[#6B7280] font-medium ml-2">
                    vs last month
                  </p>
                </div>
              </div>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Pages" />
            <div className="flex justify-between gap-4 items-center">
              <div>
                <h2 className="text-typo text-2xl font-medium mb-2.5">
                  3.89
                </h2>
                <div className="flex items-center">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  <span className="text-[#E0173C] text-xs font-medium block ml-1">
                    2.11%
                  </span>
                  <p className="text-sm text-[#6B7280] font-medium ml-2">
                    vs last month
                  </p>
                </div>
              </div>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
          <div className="flex justify-between gap-4 items-center p-5">
            <div className="">
              <h1 className="text-typo text-lg font-semibold">
                Perfomance Report
              </h1>
              <div className="flex gap-2 items-center">
                <p className="text-sm font-medium text-[#6B7280]">
                  Compare to previous month
                </p>
                <div className="flex w-fit rounded-full gap-0.5 bg-[#12A380]/10 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
                  <IoIosTrendingUp className="text-[#047857]" />
                  2.11%
                </div>
              </div>
            </div>
            <div className="flex gap-2 flex-wrap">
              <SelectDropdown
                displayValue="name"
                placeholder="Select Chart"
                options={options}
                noDefault={true}
              />
              <SelectDropdown
                displayValue="name"
                placeholder="This Month"
                options={months}
                noDefault={true}
              />
            </div>
          </div>
          <LineCurveTwobarGraph />
          <div className="py-8 px-5 items-center gap-6 justify-center grid grid-cols-4">
            <div className="border-l-4 border-[#6956E5] pl-3">
              <div className="flex gap-2 justify-between mb-1.5 items-center">
                <p className="text-sm font-medium text-[#6B7280]">Desktop</p>
                <span className="text-[#E0173C] text-xs font-medium ml-1 flex">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  2.11%
                </span>
              </div>
              <div className="flex gap-2 justify-between items-center">
                <p className="text-base font-semibold text-typo">180,507</p>
                <span className="text-[#6B7280] text-sm font-medium ml-1">
                  vs last month
                </span>
              </div>
            </div>
            <div className="border-l-4 border-[#6956E5] pl-3">
              <div className="flex gap-2 justify-between mb-1.5 items-center">
                <p className="text-sm font-medium text-[#6B7280]">Tablet</p>
                <span className="text-[#E0173C] text-xs font-medium ml-1 flex">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  2.11%
                </span>
              </div>
              <div className="flex gap-2 justify-between items-center">
                <p className="text-base font-semibold text-typo">6507</p>
                <span className="text-[#6B7280] text-sm font-medium ml-1">
                  vs last month
                </span>
              </div>
            </div>
            <div className="border-l-4 border-[#6956E5] pl-3">
              <div className="flex gap-2 justify-between mb-1.5 items-center">
                <p className="text-sm font-medium text-[#6B7280]">Mobile</p>
                <span className="text-[#E0173C] text-xs font-medium ml-1 flex">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  2.11%
                </span>
              </div>
              <div className="flex gap-2 justify-between items-center">
                <p className="text-base font-semibold text-typo">139,507</p>
                <span className="text-[#6B7280] text-sm font-medium ml-1">
                  vs last month
                </span>
              </div>
            </div>
            <div className="border-l-4 border-[#6956E5] pl-3">
              <div className="flex gap-2 justify-between mb-1.5 items-center">
                <p className="text-sm font-medium text-[#6B7280]">
                  All Devices
                </p>
                <span className="text-[#E0173C] text-xs font-medium ml-1 flex">
                  <IoIosTrendingDown className="size-4 text-[#E0173C]" />
                  2.11%
                </span>
              </div>
              <div className="flex gap-2 justify-between items-center">
                <p className="text-base font-semibold text-typo">352,6507</p>
                <span className="text-[#6B7280] text-sm font-medium ml-1">
                  vs last month
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex max-xl:flex-col gap-6 mb-6">
          <div className="w-full xl:w-[60%] shadow-box border border-grey bg-white rounded-large">
            <h1 className="text-typo text-lg font-semibold p-5">Top State</h1>
            <div className="shadow-box bg-white overflow-hidden">
              <table class="w-full">
                <thead>
                  <tr class="!bg-[#F7FAFF] border-t border-[#EAECF0]">
                    <th class="px-5 py-3 text-sm font-normal text-[#3C3E49A3] text-left min-w-[200px] border-b border-[#EAECF0]">
                      Name
                      <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                    </th>
                    <th class="px-5 py-3 text-sm font-normal text-[#3C3E49A3] text-left min-w-[50px] border-b border-[#EAECF0]">
                      User
                    </th>
                    <th class="px-5 py-3 text-sm font-normal text-[#3C3E49A3] text-left min-w-[50px] border-l border-[#EAECF0] border-b">
                      %
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[#EAECF0] last:border-none">
                    <td className="px-5 py-3 text-sm font-normal text-typo text-left">
                      <p className="text-sm flex items-center gap-2">
                        California
                      </p>
                    </td>
                    <td className="px-5 py-3 text-sm font-normal text-typo text-left">
                      <p className="text-sm">675</p>
                    </td>
                    <td className="px-5 py-3 text-sm font-normal text-typo text-left border-l border-[#EAECF0]">
                      <p className=" text-sm">40%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-2.5 h-16 flex items-center justify-center border-t mt-auto">
              <Link
                to="#"
                className="!text-primary hover:!text-primary hover:opacity-80 flex items-center gap-2 text-sm font-normal"
              >
                View all details data
                <FiChevronRight className="text-xl" />
              </Link>
            </div>
          </div>
          <div className="w-full xl:w-[40%] shadow-box border border-grey bg-white rounded-large">
            <div className="flex justify-between gap-4 items-center p-5">
              <h1 className="text-typo text-lg font-semibold">Users</h1>
              <SelectDropdown
                displayValue="name"
                placeholder="Select Chart"
                options={options}
                noDefault={true}
              />
            </div>
            <PieCircleChart />
            <div className="p-2.5 h-16 flex items-center justify-center border-t mt-auto">
              <Link
                to="#"
                className="!text-primary hover:!text-primary hover:opacity-80 flex items-center gap-2 text-sm font-normal"
              >
                View all details data
                <FiChevronRight className="text-xl" />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex max-xl:flex-col gap-6 mb-6">
          <div className="w-full xl:w-[60%] shadow-box border border-grey bg-white rounded-large">
            <div className="flex justify-between gap-4 items-center p-5">
              <div className="">
                <h1 className="text-typo text-lg font-semibold">
                  Device types
                </h1>
                <div className="flex gap-2 items-center">
                  <p className="text-sm font-medium text-[#6B7280]">
                    Compare to previous month
                  </p>
                  <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
                    <IoIosTrendingUp className="text-[#047857]" />
                    2.11%
                  </div>
                </div>
              </div>
              <div className="flex gap-2 max-2xl:flex-wrap justify-end">
                <SelectDropdown
                  displayValue="name"
                  placeholder="Select Chart"
                  options={options}
                  noDefault={true}
                />
                <SelectDropdown
                  displayValue="name"
                  placeholder="This Month"
                  options={months}
                  noDefault={true}
                />
              </div>
            </div>
            <VerticalBarGraph colors={["#6956E5", "#B0A5F5", "#5ADF91"]} />
          </div>
          <div className="w-full xl:w-[40%] shadow-box border border-grey bg-white rounded-large">
            <div className="flex justify-between gap-4 items-center p-5">
              <h1 className="text-typo text-lg font-semibold">Top State</h1>
              <SelectDropdown
                displayValue="name"
                placeholder="Select Chart"
                options={options}
                noDefault={true}
              />
            </div>
            <HorizontalBarGraph />
          </div>
        </div>
        <div className="flex max-xl:flex-col gap-6 mb-6">
          <div className="w-full xl:w-[60%] shadow-box border border-grey bg-white rounded-large">
            <div className="flex justify-between gap-4 items-center p-5">
              <div className="">
                <h1 className="text-typo text-lg font-semibold">
                  Device Top source of traffic
                </h1>
                <div className="flex gap-2 items-center">
                  <p className="text-sm font-medium text-[#6B7280]">
                    Compare to previous month
                  </p>
                  <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
                    <IoIosTrendingUp className="text-[#047857]" />
                    2.11%
                  </div>
                </div>
              </div>
              <div className="flex gap-2 max-2xl:flex-wrap justify-end">
                <SelectDropdown
                  displayValue="name"
                  placeholder="Select Chart"
                  options={options}
                  noDefault={true}
                />
                <SelectDropdown
                  displayValue="name"
                  placeholder="This Month"
                  options={months}
                  noDefault={true}
                />
              </div>
            </div>
            <VerticalBarGraph colors={["#8064FF", "#F472B6", "#5ADF91"]} />
          </div>
          <div className="w-full xl:w-[40%] shadow-box border border-grey bg-white rounded-large">
            <div className="flex justify-between gap-4 items-center p-5">
              <h1 className="text-typo text-lg font-semibold">
                % split of traffic by source
              </h1>
              <SelectDropdown
                displayValue="name"
                placeholder="Select Chart"
                options={options}
                noDefault={true}
              />
            </div>
            <PieCircleChart />
            <div className="p-2.5 h-16 flex items-center justify-center border-t mt-auto">
              <Link
                to="#"
                className="!text-primary hover:!text-primary hover:opacity-80 flex items-center gap-2 text-sm font-normal"
              >
                View all payment data
                <FiChevronRight className="text-xl" />
              </Link>
            </div>
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 p-5 items-center">
            <h1 className="text-typo text-lg font-semibold">
              Key landing pages
            </h1>
            <SelectDropdown
              displayValue="name"
              placeholder="This Month"
              options={months}
              noDefault={true}
            />
          </div>
          <table className="w-full">
            <thead className="">
              <tr className="w-full">
                <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  New Users
                  <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Sessions
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Users
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Session Duration
                </th>
                <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Bounce Rate
                </th>
                <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Pages
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                  316,796
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  443,117
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  325,436
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0] text-center">
                  2h 53s
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  48.57%
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                  3.89
                </td>
              </tr>
            </tbody>
          </table>
          <div className="p-2.5 h-16 flex items-center justify-center border-t mt-auto">
            <Link
              to="#"
              className="!text-primary hover:!text-primary hover:opacity-80 flex items-center gap-2 text-sm font-normal"
            >
              View all data
              <FiChevronRight className="text-xl" />
            </Link>
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 p-5 items-center">
            <h1 className="text-typo text-lg font-semibold">Keywords</h1>
            <SelectDropdown
              displayValue="name"
              placeholder="This Month"
              options={months}
              noDefault={true}
            />
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    New Users
                    <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Sessions
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Users
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Session Duration
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Bounce Rate
                  </th>
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Pages
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                    316,796
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    443,117
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    325,436
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0] text-center">
                    2h 53s
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                    48.57%
                  </td>
                  <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                    3.89
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-2.5 h-16 flex items-center justify-center border-t mt-auto">
            <Link
              to="#"
              className="!text-primary hover:!text-primary hover:opacity-80 flex items-center gap-2 text-sm font-normal"
            >
              View all data
              <FiChevronRight className="text-xl" />
            </Link>
          </div>
        </div>
      </Layout>
      ;
    </div>
  );
};

export default MarketingInsights;
