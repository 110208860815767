// src/components/OverviewCards.js
import React from 'react';
import { IoMdTrendingUp } from "react-icons/io";

import { IoMdTrendingDown } from "react-icons/io";
import pipeModel from '../../models/pipeModel';
import { useSelector } from 'react-redux';


const OverviewCards = ({ cardsData }) => {
    const user = useSelector(state => state.user);
    return (
        <div className='bg-white p-6 rounded-lg shadow mt-6'>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 divide-x-2   divide-gray-100">
                {/* <div className="">
                    <h3 className="text-md mb-2 font-normal text-primary">Order</h3>
                    <p className="text-[26px] font-medium">567</p>

                    <div className='flex items-center gap-4 mt-2 mb-2'>
                        <div className='flex gap-1 items-center'>
                            <p className='font-normal text-gray-400 text-lg'>520</p>
                            <p className='font-normal text-gray-400 text-sm'>(Last Year)</p>
                        </div>

                        <p className="text-sm text-green-500 border-green-200 border bg-green-50 px-2 py-1 rounded-full flex items-center gap-1"> <span><IoMdTrendingUp /> </span> 10%</p>

                    </div>

                </div> */}
                <div className="px-4">
                    <h3 className="text-md mb-2 font-normal text-primary">Pax</h3>
                    <p className="text-[26px] font-medium">{pipeModel.number(cardsData?.actualPax || 0)}</p>

                    {/* <div className='flex items-center gap-4 mt-2 mb-2'>
                        <div className='flex gap-1 items-center'>
                            <p className='font-normal text-gray-400 text-lg'>520</p>
                            <p className='font-normal text-gray-400 text-sm'>(Last Year)</p>
                        </div>

                        <p className="text-sm text-green-500 border-green-200 border bg-green-50 px-2 py-1 rounded-full flex items-center gap-1"><span><IoMdTrendingUp /> </span> 10%</p>

                    </div> */}

                </div>
                <div className="px-4 pl-8">
                    <h3 className="text-md mb-2 font-normal text-primary">Sales</h3>
                    <p className="text-[26px] font-medium">{pipeModel.currency(cardsData?.actualSale || 0, '$', user?.companyCurrencyFormat)}</p>

                    {/* <div className='flex items-center gap-4 mt-2 mb-2'>
                        <div className='flex gap-1 items-center'>
                            <p className='font-normal text-gray-400 text-lg'>520</p>
                            <p className='font-normal text-gray-400 text-sm'>(Last Year)</p>
                        </div>

                        <p className="text-sm text-red-500 border-red-200 border bg-red-50 px-2 py-1 rounded-full flex items-center gap-1"><span><IoMdTrendingDown /> </span> 10%</p>

                    </div> */}

                </div>
            </div>
        </div>
    );
};

export default OverviewCards;
