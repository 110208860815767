import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const HorizontalBarGraph = () => {
  const user = useSelector((state) => state.user);

  const salesData = {
    Jan: { Desktop: 10, Mobile: 5, Tablet: 2 },
    Feb: { Desktop: 12, Mobile: 6, Tablet: 4 },
    Mar: { Desktop: 13, Mobile: 6, Tablet: 5 },
    Apr: { Desktop: 15, Mobile: 7, Tablet: 3 },
    May: { Desktop: 14, Mobile: 6, Tablet: 4 },
    Jun: { Desktop: 16, Mobile: 6, Tablet: 5 },
  };

  const months = Object.keys(salesData);
  const platforms = ["Desktop", "Mobile", "Tablet"];

  const updatedOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: platforms,
      top: "0%",
      itemGap: 10,
      textStyle: {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    xAxis: {
      type: "value",
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      data: months,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false, 
      },
      axisLabel: {
        fontSize: 14,
        fontWeight: 500,
        color: "#6B7280",
        margin: 20,
      },
    },
    series: platforms.map((platform, index) => ({
      name: platform,
      type: "bar",
      stack: "total",
      label: {
        show: false,
      },
      data: months.map((month) => salesData[month][platform]),
      itemStyle: {
        borderRadius: index === 0 ? [5, 5, 5, 5] : [0, 0, 0, 0],
        color:
          platform === "Desktop"
            ? "#6956E5"
            : platform === "Mobile"
            ? "#B0A5F5"
            : "#5ADF91",
      },
      barWidth: 35,
      barWidth: 20,
      barGap: "50%", 
      barCategoryGap: "30%",    
      borderwidth: 40,
    })),
    color: ["#6956E5", "#B0A5F5", "#5ADF91"],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} height="350px" />
    </div>
  );
};

export default HorizontalBarGraph;
