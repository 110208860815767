// src/components/ProductBreakdownTable.js
import React, { useEffect, useState } from 'react';
import SelectDropdown from '../../components/common/SelectDropdown';

const ProductBreakdownTable = ({ type = null, title = 'Forecasting Breakdown by Products', ResponseData, UniversalProducts, filter, setfilter, getData, Loaders }) => {


    // Transform function
    const TransFormedData = ResponseData?.data?.map((dataItem) => {
        // Group availabilities by date and format the time slots with price
        const groupedByDate = dataItem?.productavailablities?.reduce((acc, avail) => {
            const dateKey = avail.startTimeLocal.split("T")[0];
            const startTime = new Date(avail.startTimeLocal).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
            const endTime = new Date(avail.endTimeLocal).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
            const timeSlot = `${startTime} to ${endTime}`;

            // Initialize date entry if not present
            if (!acc[dateKey]) {
                acc[dateKey] = { date: dateKey, slots: { total: 0, times: [] } };
            }

            // Add the time slot and price to the date entry
            acc[dateKey].slots.times.push({
                total: avail?.seatsAvailable,
                timeSlot,
                price: avail.priceOptions.map(option => `$${option.price}`)
            });
            acc[dateKey].slots.total += avail.seatsAvailable;

            return acc;
        }, {});

        // Format grouped data as an array
        const daysArray = Object.keys(groupedByDate).map((key) => {
            const date = new Date(key);
            const dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
            const dayString = date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
            return {
                date: dayName,
                day: dayString,
                slots: groupedByDate[key].slots
            };
        });

        // Return the final transformed object
        return {
            product: dataItem?.product_code,
            timeSlots: Array.from(new Set(dataItem?.productavailablities?.map(avail => {
                const start = new Date(avail?.startTimeLocal).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
                const end = new Date(avail?.endTimeLocal).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
                return `${start} to ${end}`;
            }))),
            days: daysArray
        };
    });


    const [forecastData, setForecastData] = useState(TransFormedData && TransFormedData[0]);

    const demandColor = (slot) => {
        if (slot === 'Full' || slot === '0') return 'bg-white'; // Not Available
        if (slot === '$250') return 'bg-green-100'; // High Demand
        return 'bg-red-100'; // Low Demand
    };

    useEffect(() => {
        // Simulate an API call by fetching dynamic data
        // Uncomment below to fetch from API
        // fetch('/api/forecast')
        //   .then(response => response.json())
        //   .then(data => setForecastData(data));

        // For now, we are using the static forecastData
    }, []);


    const handleDateRangeChange = (range) => {
        const currentDate = new Date();
        let startDate = new Date();
        let endDate = new Date();

        switch (range) {
            case '15 Days':
                endDate.setDate(currentDate.getDate() + 15);
                break;
            case '1 Month':
                endDate.setMonth(currentDate.getMonth() + 1);
                break;
            case '2 Months':
                endDate.setMonth(currentDate.getMonth() + 2);
                break;
            case '3 Months':
                endDate.setMonth(currentDate.getMonth() + 3);
                break;
            default:
                break;
        }

        setfilter((prev) => ({
            ...prev,
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
        }));

        getData({
            ...filter,
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
        });
    };

    return (
        <div className="">

            <div className='border-b px-4 py-4 border-gray-200'>
                <div className="flex justify-between items-center">
                    <h2 className="text-[#212121] font-semibold text-[16px]">{title}</h2>
                    <div className='flex gap-2'>
                        <div className='flex gap-4 divide-x-2  divide-gray-100'>
                            <div className='flex gap-2 items-center px-4'>
                                <span className='h-4 w-4 rounded-sm bg-[#47A147]'></span>
                                <p className='text-[13px] text-[#212121]'>High Demand</p>
                            </div>
                            <div className='flex gap-2 items-center px-4 '>
                                <span className='h-4 w-4 rounded-sm bg-[#47A147]'></span>
                                <p className='text-[13px] text-[#212121]'>Low Demand</p>
                            </div>
                            <div className='flex gap-2 items-center px-4'>
                                <span className='h-4 w-4 rounded-sm bg-[#D9D9D9]'></span>
                                <p className='text-[13px] text-[#212121]'>Not Available</p>
                            </div>
                        </div>

                        <div className="flex space-x-2">
                            <SelectDropdown
                                options={UniversalProducts}
                                placeholder='Select Product'
                                result={e => {
                                    if (type) {
                                        getData({ productCode: e?.value }, type)
                                    } else {
                                        getData({ productCode: e?.value })
                                    }
                                    setfilter(prev => ({ ...prev, productCode: e?.value }))
                                }}
                                theme='search'
                                crossIcon={false}
                                intialValue={filter?.productCode}
                            />
                            <select
                                className="border px-2 py-1 rounded text-[15px]"
                                onChange={(e) => handleDateRangeChange(e.target.value)}
                            >
                                <option value="">Select Range</option>
                                <option value="15 Days">15 Days</option>
                                <option value="1 Month">1 Month</option>
                                <option value="2 Months">2 Months</option>
                                <option value="3 Months">3 Months</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>


            <div className=''>

                {forecastData?.days?.length > 0 ?
                    <table className=" w-full">
                        <thead>
                            <tr>
                                <th className='border border-gray-200 text-primary text-left text-sm p-4'>Name of Products</th>
                                {forecastData?.days?.map((day, idx) => (
                                    <th className='border border-gray-200 p-4' key={idx} >
                                        <p className='text-primary font-normal'>{day.day}</p>
                                        <p className='text-gray-400 font-normal'>  {day?.date}</p>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='border border-gray-200 p-4'>
                                    <p className='text-primary text-sm font-normal'>Available slots for </p>
                                    <p className='text-lg font-medium'>{forecastData?.product}</p>
                                </td>
                                {forecastData?.days?.map((day, idx) => (
                                    <td className='border border-gray-200 p-4' key={idx} >
                                        <p className='text-center mb-2'>{day.slots.total}</p>

                                        <div class="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                                            <div className="bg-primary h-2 rounded-full" style={{ width: '45%' }}></div>

                                        </div>

                                    </td>
                                ))}
                            </tr>

                            {forecastData?.timeSlots?.map((time, idx) => (
                                <tr key={idx}>
                                    <td className='border border-gray-200 p-4 
                            font-lg font-medium text-[14px]' >{time}</td>

                                    {forecastData.days.map((day, dayIdx) => (
                                        <td key={dayIdx} className={`text-center border border-gray-200 p-4 ${demandColor(day.slots.times[idx]?.price[0])}`}>
                                            <div className='flex-col items-center gap-2 '>
                                                <p className='font-normal text-neutral-700 text-sm'>AUD</p>
                                                <span className='font-medium text-neutral-800 text-lg'>{day.slots.times[idx]?.price[0]}</span>
                                                <p className='font-normal text-neutral-700 text-sm'>{day.slots.times[idx]?.total}</p>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table> : <div className='text-center p-3'>{Loaders?.breakdownTable ? null : 'No Data'}</div>}
                {Loaders?.breakdownTable ? <p className='m-4 text-center'>Loading ....</p> : null}
            </div>

        </div>
    );
};

export default ProductBreakdownTable;
