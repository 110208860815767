import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import colorsModel from '../../../models/colors.model';
import pipeModel from '../../../models/pipeModel';
import { useSelector } from 'react-redux';

const LineChart = ({
    data=[],
    legends=[],
    hideLegends = true, showReply = false, refundData =false,showSaleFormat=false,
}) => {
    const colors=colorsModel.list
const user=useSelector(state=>state.user);
    const [overtimeLoader, setOvertimeLoader] = useState(false)
    const [hiddenItem, setHiddenItem] = useState([])
    const ToolTipReturner =(tooltipData)=>{
    const DataReturner=(data)=>{
        if(data.seriesName?.includes('Sales')){
            return pipeModel.currency(data.value,'$',user?.companyCurrencyFormat) 
        }else{
            if(data?.seriesName?.includes('Lead')){
                return data.value +' days'
            }else{
            return pipeModel.number(data.value)==NaN || data.value
            }
        }
    }
let initialTooltip=``;
tooltipData.forEach((item)=>{
    initialTooltip += `<b>${item?.seriesName} </b> : <b> ${DataReturner(item)}</b> <br/>`
})
return initialTooltip
}


    const legendClick=(itm)=>{
        let arr=hiddenItem
        if(!showReply){
        if(arr.find(fitm=>fitm.key==itm.key)){
            arr=arr.filter(fitm=>fitm.key!=itm.key)
        }else{
            arr.push(itm)
        }
    }else{
        if(itm?.key!='All'){
            arr=arr.filter((item)=>item?.key!=itm?.key)
            legends.map((item)=>{
                if(item?.key!=itm?.key){
                    arr.push(item)
                }
            })
        }else{
            arr=[] 
        }
    }
        setHiddenItem([...arr])
        setOvertimeLoader(true)
        setTimeout(() => {
            setOvertimeLoader(false)
        }, 100);
    }

    const performanceLegends = [...legends.filter(itm=>!itm?.hide).map(itm=>itm.label)]
    const performanceOption = {
        tooltip: {
            trigger: 'axis',
            formatter: !showReply && !showSaleFormat ? refundData ? function (data) {
                
                    return `<b>Refund </b>: ${pipeModel.currency(data[0].data, '$', user?.companyCurrencyFormat)}`
                
}  :function(dat){
    return ToolTipReturner(dat)
} :function(params){
    if (showSaleFormat) {
        return `<b>Sales </b>: ${pipeModel.currency(params[0].data, '$', user?.companyCurrencyFormat)} <br/><b>Bookings </b>: ${pipeModel.number(params[1].data)}`
    } 
                const FindedData = data.find((item,index) =>{
                    if( item?.date == params[0]?.axisValueLabel){
                    let key = params[0]?.seriesName.trim()
                    return item[key]
                }else{
                    return null
                }
            });
            
                let ParamsKeys = params.map((item) => item?.seriesName);

let FinalizedData=ParamsKeys.map((item)=>{
                    return {key:item,data:FindedData[item.trim()]?FindedData[item.trim()]:{count:0}}
                })

let tooltip=''
FinalizedData.map((item)=>{
    tooltip += `<b> ${item?.key}</b>: ${item?.data?.count||0}  (<b> Replied</b>:${item?.data?.replied||0} | <b>Not Replied</b>:${item?.data?.unreplied||0} ) <br/>`
})
return tooltip
                
            }
        },
        legend:hideLegends?null:{
          data: [...performanceLegends]
          
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: [
                    ...data.map(itm => itm.date)
                ]
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            ...legends.map((itm,i)=>{
                return {
                    name: itm.label,
                    key:itm.key,
                    type: 'line',
                    itemStyle: {normal: {areaStyle: {type: 'default'},
                    color: colors?.[i]}},

                    data: [
                        ...data.map(ditm => !showReply ? ditm[itm.key] || 0:ditm[itm.key]['count']||0),
                    ]
                }
            }).filter(fitm=>{
                let ext=hiddenItem.find(eitm=>eitm.key==fitm.key||eitm.compare==fitm.key)
                let value=true
                if(ext){
                    value=false
                    if(ext?.compare==fitm.key){
                        value=false
                    }
                }
        
                return value
            })
        ],
        additionalData:[
            0,0,0,0,0,0
        ]
    };

    useEffect(()=>{
        if(data?.length){
            setOvertimeLoader(true)
            setTimeout(()=>{
                setOvertimeLoader(false)
            },800)
        
        }
    },[data])

    return <>
        {overtimeLoader ? <>
            <div className='shine shineCard'></div>
        </> : <>
        <div>
            <div className='legends flex gap-2'>
                {legends.map((itm,i)=>{
                    if(!itm.hide)
                    return <>
                <div key={i} className={`legendItem flex ${hiddenItem.find(fitm=>fitm.key==itm.key)?'active':''}`} onClick={()=>legendClick(itm)}>
                    <div className='color' style={{background:colorsModel.list[i]}}></div>
                    {itm.label}</div>
                    </>
                })}
            </div>
            <ReactECharts
                option={performanceOption}
                style={{ height: 400 }}
                opts={{renderer:'svg'}}
            />
        </div>
        </>}
    </>
}

export default LineChart