import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from "echarts";
import SelectDropdown from '../../components/common/SelectDropdown';

const PerformanceChart = ({ ResponseData, getData, filter, setfilter, UniversalProducts, Loaders }) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [selectedOption, setselectedOption] = useState('pax');
    const [timeFilter, setTimeFilter] = useState('monthly');  // New state to handle monthly/weekly filter

    // Get unique months or weeks based on selected time filter
    const getUniqueTimePeriods = () => {
        let timePeriods = [];

        if (filter?.type === 'monthly') {
            // Get months
            const allDates = ResponseData?.actualPaxAndSale?.map(item => ({
                year: item._id.date_year,
                month: item._id.date_month
            }));

            timePeriods = Array?.from(
                new Set(allDates?.map(item => `${monthNames[item.month - 1]} ${item.year}`))
            );
        } else if (filter?.type === 'weekly') {
            // Get weeks (example: Week 1, Week 2, etc.)
            const allDates = ResponseData?.actualPaxAndSale?.map(item => ({
                year: item._id.date_year,
                week: item._id.date_week
            }));

            timePeriods = Array?.from(
                new Set(allDates?.map(item => `Week ${item.week} ${item.year}`))
            );
        }
        return timePeriods;
    };

    const dates = getUniqueTimePeriods();

    // Helper function to get data for actualPax, actualAmount, TargetPax, TargetSales, etc.
    const getDataForTimePeriod = (data, key) => {
        return dates.map(date => {
            let dataDate = date;
            const [period, year] = date.split(' ');
            const entry = data?.find(item => {
                if (filter?.type === 'monthly') {
                    const monthIndex = monthNames.indexOf(period) + 1;
                    return item._id.date_month === monthIndex && item._id.date_year === parseInt(year);
                } else if (filter?.type === 'weekly') {
                    return item._id.date_week == parseInt(dataDate.split(' ')[1]) && item._id.date_year == parseInt(dataDate.split(' ')[2]);
                }
            });
            return entry ? entry[key] : 0;
        });
    };

    const actualPax = getDataForTimePeriod(ResponseData?.actualPaxAndSale, 'totalPax');
    const actualAmount = getDataForTimePeriod(ResponseData?.actualPaxAndSale, 'totalAmount');
    const TargetPax = getDataForTimePeriod(ResponseData?.targetData, 'targetedPax');
    const TargetSales = getDataForTimePeriod(ResponseData?.targetData, 'targetedSale');

    const getOption = () => ({
        tooltip: { trigger: 'axis' },
        legend: {
            data: selectedOption === 'pax' ? ['Actuals Pax', 'Target Pax', 'Forecast Pax', 'Last Years', 'Maximum Capacity'] : ['Actuals Sales', 'Forecast Sales', 'Target Sales', 'Last Years', 'Maximum Capacity'],
            top: '-3%',
            left: '1%',
            padding: [20, 0, 0, 0]
        },
        xAxis: {
            type: 'category',
            data: dates,
            axisLabel: {
                rotate: 0,
                margin: 20,
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                rotate: 0,
                margin: 40,
            }
        },
        series: selectedOption === 'pax' ? [
            {
                name: 'Actuals Pax',
                type: 'bar',
                data: actualPax,
                stack: 'total',
                itemStyle: {
                    color: '#3B3A9A',
                    borderRadius: [8, 8, 0, 0],
                },
                barWidth: 50,
                showBackground: true,
            },
            {
                name: 'Target Pax',
                type: 'line',
                data: TargetPax,
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: '#FFAD34'
                }
            },
            {
                name: 'Forecast Pax',
                type: 'bar',
                data: Array(dates.length).fill(10),
                stack: 'total',
                itemStyle: {
                    color: '#A7A2E8',
                    borderRadius: [8, 8, 0, 0]
                },
                barWidth: 50,
            },
            {
                name: 'Last Years',
                type: 'line',
                data: ResponseData?.forcastingData?.map(item => item.lastYearValue),
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: '#FFD700'
                }
            },
            {
                name: 'Maximum Capacity',
                type: 'line',
                data: Array(dates.length).fill(40000),
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: '#31A354'
                }
            }
        ] : [
            {
                name: 'Actuals Sales',
                type: 'bar',
                data: actualAmount,
                stack: 'total',
                itemStyle: {
                    color: '#2179b8',
                    borderRadius: [8, 8, 0, 0]
                },
                barWidth: 50,
                showBackground: true,
            },
            {
                name: 'Target Sales',
                type: 'line',
                data: TargetSales,
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: '#FFAD34'
                }
            },
            {
                name: 'Forecast Sales',
                type: 'bar',
                data: ResponseData?.forcastingData?.map(item => item.forecastValue),
                stack: 'total',
                itemStyle: {
                    color: '#A7A2E8',
                    borderRadius: [8, 8, 0, 0]
                },
                barWidth: 50,
            },
            {
                name: 'Last Years',
                type: 'line',
                data: ResponseData?.forcastingData?.map(item => item.lastYearValue),
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: '#FFD700'
                }
            },
            {
                name: 'Maximum Capacity',
                type: 'line',
                data: Array(dates.length).fill(40000),
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: '#31A354'
                }
            }
        ]
    });

    return (
        <div>
            <div className="flex justify-between items-center border-b px-4 py-4 border-gray-200">
                <h2 className="text-[#212121] font-semibold text-[16px]">Forecasting Report</h2>
                <div className='flex'>
                    <SelectDropdown
                        options={[{ id: new Date().getFullYear(), name: 'Current Year' }, { id: new Date().getFullYear() - 1, name: 'Previous Year' }, { id: new Date().getFullYear() + 1, name: 'Next Year' }]}
                        showPlaceHolder={false}
                        result={e => {
                            getData({ startDate: `${e?.value}-01-01`, endDate: `${e?.value}-12-31` });
                            setfilter(prev => ({ ...prev, startDate: `${e?.value}-01-01`, endDate: `${e?.value}-12-31` }));
                        }}
                        className='mr-3'
                        intialValue={filter?.startDate?.split('-')[0]}
                    />
                    <SelectDropdown
                        options={[{ id: 'pax', name: 'By Pax' }, { id: 'sale', name: 'By Sales' }]}
                        showPlaceHolder={false}
                        result={e => {
                            getData({});
                            setselectedOption(e?.value);
                        }}
                        crossIcon={false}
                        className='mr-3'
                        theme='search'
                        placeholder='Select Type'
                        intialValue={selectedOption}
                    />
                    <SelectDropdown
                        options={[{ id: 'monthly', name: 'Monthly' }, { id: 'weekly', name: 'Weekly' }]}
                        showPlaceHolder={false}
                        result={e => {
                            setfilter(prev => ({ ...prev, type: e?.value }));
                            getData({ type: e?.value })
                        }}
                        crossIcon={false}
                        className='mr-3'
                        theme='search'
                        placeholder='Select Time Filter'
                        intialValue={filter?.type}
                    />
                    <SelectDropdown
                        options={UniversalProducts}
                        placeholder='Select Product'
                        result={e => {
                            getData({ productCode: e?.value });
                            setfilter(prev => ({ ...prev, productCode: e?.value }));
                        }}
                        crossIcon={false}
                        theme='search'
                        className='max-w-[160px]'
                        intialValue={filter?.productCode}
                    />
                </div>
            </div>
            <div className="px-4 py-6">
                <ReactECharts
                    option={getOption()}
                    notMerge={true}
                    lazyUpdate={true}
                    theme="light"
                    echarts={echarts}
                />
            </div>
        </div>
    );
}

export default PerformanceChart;
