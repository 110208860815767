import React, { Fragment, useEffect } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import methodModel from "../../../methods/methods";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../actions/user";
import { Menu, Transition } from "@headlessui/react";
import { FiChevronDown, FiLogOut } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { RiHome6Line } from "react-icons/ri";

const PageLayout = ({ children }) => {
  const WebDetails=useSelector(state=>state.logo);
  const user = useSelector((state) => state.user);
  const history = useNavigate();
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("persist:admin-app");
    localStorage.removeItem("token");
    history("/login");
  };

  return (
    <>
      <nav className={`shadow-btn py-3 bg-white transition-[width] duration-300 ml-auto right-0 z-50 !px-5 absolute top-0 left-0 w-full`}>
        <div className="flex items-center justify-between">
          <Link rel="preload" to="/dashboard" className="navbar-brand">
            <img
              src={WebDetails.logo}
              className="max-w-[250px] object-contain w-full"
              width="auto"
              height="auto"
            />
          </Link>
          {user?.id || user?._id ? (
            <Menu as="div" className="relative text-left">
              <div>
                <Menu.Button className="hover:bg-black/5 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 flex items-center justify-center w-full gap-2 px-2 py-2 rounded-large">
                  <img
                    alt="image"
                    src={methodModel.userImg(user.image)}
                    className="object-cover w-10 h-10 rounded-full"
                    width="40"
                    height="40"
                  />
                  <div>
                    <p className="font-medium text-[#2D3036] text-sm">{user.fullName}</p>
                  </div>
                  <FiChevronDown className="w-5 h-5 text-[#2D3036]" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="ring-1 ring-black/5 focus:outline-none w-60 !rounded-large absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg">
                  <div className=" px-2 py-2">
                    <Menu.Item>
                      <Link
                        to="/dashboard"
                        className="group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                        <RiHome6Line />
                        Dashboard
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to="/profile"
                        className="group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                        <FaRegUser />
                        Profile
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to="/profile/change-password"
                        className="group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                        <IoSettingsOutline />
                        Change Password
                      </Link>
                    </Menu.Item>
                  </div>
                  <div className="px-2 py-2">
                    <Menu.Item>
                      <button
                        onClick={() => Logout()}
                        className="group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center w-full gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                        <FiLogOut />
                        Logout
                      </button>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <>
              <Link
                to="/login"
                className="w-fit ml-auto group hover:bg-gray-100 hover:no-underline hover:!text-primary flex items-center gap-3 px-2 py-2 text-sm font-normal text-gray-900 rounded-large">
                Login
              </Link>
            </>
          )}

        </div>
      </nav>
      <div className="p-5 mt-20">{children}</div>

      <ToastContainer position="top-right" />
    </>
  );
};
export default PageLayout;
