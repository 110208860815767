// src/components/TargetsTable.js
import React from 'react';
import SelectDropdown from '../../components/common/SelectDropdown';
import { FutureYearList } from '../../models/months.model';
import pipeModel from '../../models/pipeModel';
import { useSelector } from 'react-redux';
import methodModel from '../../methods/methods';

const TargetsTable = ({ Responsedata, getData, filter, setfilter, UniversalProducts, Loaders }) => {
    const user = useSelector(state => state.user)




    return (

        <>

            <div className="">

                <div className='border-b px-4 py-4 border-gray-200'>
                    <div className="flex justify-between items-center">
                        <h2 className="text-[#212121] font-semibold text-[16px]">Targets by Products</h2>



                        <div className="flex space-x-2">
                            <SelectDropdown
                                options={FutureYearList}
                                className='mr-2'
                                showPlaceHolder={false}
                                intialValue={filter?.year}
                                result={e => {
                                    setfilter(prev => ({ ...prev, year: e?.value }))
                                    getData({ year: e?.value })
                                }}
                            />


                            <SelectDropdown
                                options={UniversalProducts}
                                crossIcon={false}
                                placeholder='Select Product'
                                result={e => {
                                    getData({ productCode: e?.value })
                                    setfilter(prev => ({ ...prev, productCode: e?.value }))
                                }}
                                theme='search'
                                intialValue={filter?.productCode}
                            />
                        </div>

                    </div>
                </div>


                <div className=''>



                    {Loaders?.targetLoader ? <p className='m-4 p-4 text-center'>Loading ....</p> : <table className='w-full'>
                        <thead>
                            <tr>
                                <th className='p-3 border border-gray-200'></th>
                                <th colspan="2" className='p-3 border text-primary text-[14px] font-medium border-gray-200'>PAX</th>
                                <th colspan="2" className='p-3 border text-primary text-[14px] font-medium border-gray-200'>Price</th>
                                <th colspan="2" className='p-3 border text-primary text-[14px] font-medium border-gray-200'>Sales</th>
                            </tr>
                            <tr>
                                <th className='p-3 border font-medium text-[14px] text-primary border-gray-200'>Month & Years</th>
                                <th className='p-3 border font-medium text-[14px] text-primary border-gray-200'>Targets</th>
                                <th className='p-3 border font-medium text-[14px] text-primary border-gray-200'>Forecast AI</th>
                                <th className='p-3 border font-medium text-[14px] text-primary border-gray-200'>Targets</th>
                                <th className='p-3 border font-medium text-[14px] text-primary border-gray-200'>Forecast AI</th>
                                <th className='p-3 border font-medium text-[14px] text-primary border-gray-200'>Targets</th>
                                <th className='p-3 border font-medium text-[14px] text-primary border-gray-200'>Forecast AI</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Responsedata?.map((item) => (<tr>
                                <td className="p-3 text-[14px] border border-gray-200">{methodModel.MonthNameGetter(item?.month)}, {item?.year}</td>
                                <td className="p-3 text-[14px] border border-gray-200" >
                                    <p className='border text-center border-gray-100 px-4 py-2 rounded-lg'>{pipeModel.number(item?.targetedPax)}</p>
                                </td>
                                <td className="p-3 text-[14px] border border-gray-200 text-center " >{pipeModel?.number(item?.predictedPax)}</td>
                                <td className="p-3 text-[14px] border border-gray-200 text-center" >
                                    <p className='border text-center border-gray-100 px-4 py-2 rounded-lg'>{pipeModel.currency(item?.targetedPrice, '$', user?.companyCurrencyFormat)}</p>
                                </td>
                                <td className="p-3 text-[14px] border border-gray-200 text-right" >{pipeModel.currency(item?.predictedPrice, '$', user?.companyCurrencyFormat)}</td>
                                <td className="p-3 text-[14px] border border-gray-200 text-right" >{pipeModel.currency(item?.targetedSale, '$', user?.companyCurrencyFormat)}</td>
                                <td className="p-3 text-[14px] border border-gray-200 text-right" >{pipeModel.currency(item?.prdeictedSale, '$', user?.companyCurrencyFormat)}</td>
                            </tr>))}

                        </tbody>
                    </table>}

                    {!Responsedata?.length && !Loaders?.targetLoader ? <div className='text-center p-4'>No Data</div> : null}
                </div>

            </div>





        </>
    );
};

export default TargetsTable;
