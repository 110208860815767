import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const AudienceVerticalBarGraph = () => {
  const user = useSelector((state) => state.user);

  const audienceData = {
    academy: 5.0,
    activity: 4.0,
    ambiguity: 3.5,
    appeal: 6.0,
    breath: 2.0,
    chef: 5.5,
    detainee: 3.0,
    elevator: 1.5,
    ethics: 6.5,
    financing: 3.5,
  };

  const categories = Object.keys(audienceData);
  const audienceValues = Object.values(audienceData);

  const updatedOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: categories,
      textStyle: {
        color: "#000",
      },
      orient: "horizontal",
      top: "top",
    },
    xAxis: {
      type: "category",
      data: categories,
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 12,
        fontWeight: 500,
      },
      nameTextStyle: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 12,
        fontWeight: 500,
        formatter: (value) => {
          if (value === 0) return '0';
          if (value === 3.0) return '3.0k';
          if (value === 6.0) return '6.0k';
          return '';
        },
      },
      name: 'Audience',
      nameTextStyle: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
    series: [
      {
        name: "Audience",
        type: "bar",
        data: audienceValues,
        itemStyle: {
          color: (params) => {
            const colors = [
              "#481196",
              "#5ADF91",
              "#F9E24A",
              "#FF6F91",
              "#70C0D3",
              "#DA6BF3",
              "#A2FF8D",
              "#F86A6A",
              "#6956E5",
              "#5ADF91",
            ];
            return colors[params.dataIndex];
          },
          borderRadius: [4, 4, 0, 0],
        },
        barWidth: "20%",
      },
    ],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
      width: '95%',
    },
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default AudienceVerticalBarGraph;
