//  THIS CODE IS WITHOUT OPTIMIZATION OF THE API CALLS FOR THE MAP 
// import React from "react"
// import { useEffect } from "react"
// import { useSelector } from "react-redux";
// import pipeModel from "../../models/pipeModel"

// const CountrySalesChart = ({ className = '',
//   columns = ['Country', 'Total Sales'],
//   legends = [
//     { label: 'Total Sales', key: 'totalSale', pipe: 'currency', extrakey: ['sum'], color: "#3ac4a3" },
//     { label: 'Bookings', key: 'totalOrders', extrakey: ['numberOfOrders'], color: "#3f89f9" },
//     { label: 'Total Pax', key: 'totalPax', color: "#7777e9" },
//     { label: 'AOV', key: 'avgOrderValue', extrakey: ['avgorderValue'], color: "#ff7eb4" },
//     { label: 'Lead Times', key: 'leadTime', color: "#ffc337" },
//   ],
//   value = [{ countryName: '', sum: '' }], id = 'default', tooltipHtml = false }) => {
//   const user = useSelector((state) => state.user);
//   useEffect(() => {
//     const drawRegionsMap = () => {
//       let data = google.visualization.arrayToDataTable([
//         [...columns, { role: "tooltip", type: "string", p: { html: true } }],
//         ...value.map(itm => {

//           const setValue = (itm, data) => {
//             let value = data?.[itm.key]
//             if (itm?.extrakey && !value) {
//               itm?.extrakey.map(eitm => {
//                 if (itm?.key =='leadTime'){
//                   if (data?.[eitm]) value = Math.round(data?.[eitm])
//                 }else{
//                   if (data?.[eitm]) value = data?.[eitm]
//                 }
//               })
//             }
//             if (itm.pipe) {
//               if (itm.pipe == 'currency') value = pipeModel.currency(value, "", user.companyCurrencyFormat)
//                 else if(itm?.pipe=='text')value=value
//               else value = pipeModel.number(value)
//             } else {
//               value = itm?.key =='leadTime'? Math.round(value)+' days':pipeModel.number(value)
//             }
//             return value
//           }

//           let html = ''


//           legends.map(litm => {
//             html += `<div class="flex items-center gap-2 whitespace-nowrap mb-0.5"><span class='w-2 h-2 rounded-full' style='background-color: ${litm.color}'></span> ${litm.label}: <p class='ml-auto font-semibold'>${setValue(litm, itm)}</p></div>`
//           })

//           return [itm.countryName, itm.sum, `<div class="">

//             ${tooltipHtml ? html : `
//             ${columns[1]} : ${columns[1] == 'Total Sales' ? pipeModel.currency(itm.sum, "", user.companyCurrencyFormat) : pipeModel.number(itm.sum)} <br/>
//             `}
//             </div>`]
//         })
//       ]);
//       let options = {
//         // region: 'IT',
//         height: 350,
//         tooltip: { isHtml: true, trigger: "visible" },
//         legend: false
//       };

//       let chart = new google.visualization.GeoChart(document.getElementById(`countrySalesChart-${id}`));

//       chart.draw(data, options);
//     }
//     // google chart
//     if(google.charts){
//       google.charts.load('current', {
//         'packages': ['geochart']
//       });
//       google.charts.setOnLoadCallback(drawRegionsMap);
//     }

//   }, [value])

//   return <>
//     <div id={`countrySalesChart-${id}`} className={`googleChart w-100 ${className}`}></div>
//   </>
// }

// export default CountrySalesChart

//  THIS IS THE CODE AFTER OPTIMIZING THE API CALLS 
import React, { useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import pipeModel from "../../models/pipeModel";

const CountrySalesChart = ({
  className = '',
  columns = ['Country', 'Total Sales'],
  legends = [
    { label: 'Total Sales', key: 'totalSale', pipe: 'currency', extrakey: ['sum'], color: "#3ac4a3" },
    { label: 'Bookings', key: 'totalOrders', extrakey: ['numberOfOrders'], color: "#3f89f9" },
    { label: 'Total Pax', key: 'totalPax', color: "#7777e9" },
    { label: 'AOV', key: 'avgOrderValue', extrakey: ['avgorderValue'], color: "#ff7eb4" },
    { label: 'Lead Times', key: 'leadTime', color: "#ffc337" },
  ],
  value = [{ countryName: '', sum: '' }],
  id = 'default',
  tooltipHtml = false,
}) => {

  const user = useSelector((state) => state.user);

  const prepareData = useCallback(() => {
    try {
      return google.visualization.arrayToDataTable([
        [...columns, { role: "tooltip", type: "string", p: { html: true } }],
        ...value.map((itm) => {
          const setValue = (itm, data) => {
            let value = data?.[itm.key];
            if (itm?.extrakey && !value) {
              itm?.extrakey.forEach((eitm) => {
                if (itm?.key === 'leadTime') {
                  if (data?.[eitm]) value = Math.round(data?.[eitm]);
                } else {
                  if (data?.[eitm]) value = data?.[eitm];
                }
              });
            }
            if (itm.pipe) {
              if (itm.pipe === 'currency') {
                value = pipeModel.currency(value, "", user.companyCurrencyFormat);
              } else if (itm.pipe === 'text') {
                value = value;
              } else {
                value = pipeModel.number(value);
              }
            } else {
              value = itm?.key === 'leadTime' ? Math.round(value) + ' days' : pipeModel.number(value);
            }
            return value;
          };

          let html = '';

          legends.forEach((litm) => {
            html += `<div class="flex items-center gap-2 whitespace-nowrap mb-0.5"><span class='w-2 h-2 rounded-full' style='background-color: ${litm.color}'></span> ${litm.label}: <p class='ml-auto font-semibold'>${setValue(litm, itm)}</p></div>`;
          });

          return [
            itm.countryName,
            itm.sum,
            `<div class="">
            ${tooltipHtml ? html : `${columns[1]} : ${columns[1] === 'Total Sales' ? pipeModel.currency(itm.sum, "", user.companyCurrencyFormat) : pipeModel.number(itm.sum)} <br/>`}
          </div>`,
          ];
        }),
      ]);
    }
    catch {
    }

  }, [columns, legends, tooltipHtml, value, user]);

  const drawRegionsMap = useCallback(() => {
    try {
      const data = prepareData();
      const options = {
        height: 350,
        tooltip: { isHtml: true, trigger: "visible" },
        legend: false,
      };

      const chart = new google.visualization.GeoChart(document.getElementById(`countrySalesChart-${id}`));
      chart.draw(data, options);
    } catch {
      console.log(null)
    }
  }, [prepareData, id]);

  useEffect(() => {
    try {
      if (google && google.charts) {
        google.charts.load('current', {
          packages: ['geochart'],
        });
        google.charts.setOnLoadCallback(drawRegionsMap);
      }
    } catch {
      console.log(null)
    }
  }, [drawRegionsMap]);

  return <div id={`countrySalesChart-${id}`} className={`googleChart w-100 ${className}`}></div>;
};

export default CountrySalesChart;
