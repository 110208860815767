import { useState, useEffect, useRef } from "react";
import Layout from "../../components/global/layout";
import DateRangePicker from "../../components/common/DateRangePicker";
import { AiOutlineDownload } from "react-icons/ai";
import Badge from "../../components/Badge";
import SelectDropdown from "../../components/common/SelectDropdown";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import DeviceRoundGraph from "./GraphCharts/GoogleDeviceRoundGraph";
import { useNavigate } from "react-router-dom"
import axios from "axios";
import environment from "../../environment";
import pipeModel from "../../models/pipeModel";
import { useSelector } from "react-redux";

const GoogleAds = () => {
  const user = useSelector(state => state.user);
  const history = useNavigate();
  const [adAccountsOptions, setAdAccountsOptions] = useState([]);
  const [adAccountId, setAdAccountId] = useState(null);
  const [googleAdsAccounts, setGoogleAdsAccounts] = useState({});
  const [googleAdAccountInfo, setGoogleAdAccountInfo] = useState({});
  const [googleAdAccountInsights, setGoogleAdAccountInsights] = useState({});
  const [googleAdCampaigns, setGoogleAdCampaigns] = useState([]);
  const [googleAdCampaignInsights, setGoogleAdCampaignInsights] = useState([]);
  const [googleAdGroups, setGoogleAdGroups] = useState([]);
  const [GoogleAdFilter, setGoogleAdFilter] = useState({ status: '' })
  const [googleAdAccountDeviceInsights, setGoogleAdAccountDeviceInsights] = useState([]);
  const [adAccountYear, setAdAccountYear] = useState("current_year");
  const [accessToken, setAccessToken] = useState(null)

  const years = [
    { id: "current_year", name: "This Year" },
    { id: "last_year", name: "Last Year" },
  ];

  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    sortBy: "dateCreated desc",
    search: "",
    posId: "",
    status: "",
    productCode: "",
    category: [],
    travelStartDate: "",
    travelEndDate: "",
    reseller: "",
    third_party_delete: "",
    startDate: datepipeModel.datetostring(new Date()),
    endDate: datepipeModel.datetostring(new Date()),
    compare: false,
    compareStart: "",
    compareEnd: "",
  });
  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    };

    if (
      datepipeModel.datetostring(e.startDate) ===
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      setFilter({ ...filters, ...f });
      //dataFilter({ ...f });
    }
  };


  const getGoogleAdsAccounts = async () => {
    try {
      loader(true);
      const res = await ApiClient.getAll({
        url: 'api/google/customer-id',
        params: { access_token: accessToken },
      });
      if (res.success) {
        setGoogleAdsAccounts(res.data);
        const adAccountsData = res?.data?.customerDetails.map(resource => {
          return { id: resource?.id, name: resource?.descriptiveName };
        });
        setAdAccountsOptions(adAccountsData);
        if (adAccountsData.length > 0)
          setAdAccountId(adAccountsData[0].id);
      }
      loader(false);
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ads Accounts:', error);
    }
  };

  const getGoogleAdAcountInfo = async () => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/ad-account',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
        },
      });
      if (res.success) {
        if (res.data.length > 0 && res.data[0].results.length > 0) {
          setGoogleAdAccountInfo(res.data[0].results[0].customer);
        }
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ads Account Info:', error);
    }
  };

  const getGoogleAdAccountInsights = async (segment = "") => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/ad-account/insights',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
          start_date: filters.startDate,
          end_date: filters.endDate,
          segment: segment
        },
      });
      if (res.success) {
        if (segment == "" && res.data.length > 0 && res.data[0].results.length > 0) {
          setGoogleAdAccountInsights(res.data[0].results[0]);
        }
        else if (res.data.length > 0 && res.data[0].results.length > 0) {
          setGoogleAdAccountDeviceInsights(res.data[0].results);
        }

      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ads Account Insights:', error);
    }
  };

  const getGoogleAdCampaigns = async () => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/campaigns',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
      });
      if (res.success) {
        if (res.data.length > 0 && res.data[0].results.length > 0) {
          setGoogleAdCampaigns(res.data[0].results?.sort((a, b) => b?.metrics?.impressions - a.metrics?.impressions));
          // Loop through each campaign and await insights
          for (const result of res.data[0].results) {
            getGoogleAdCampaignsInsights(result.campaign.id);
          }
        }
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ad Campaigns:', error);
    }
  };

  const getGoogleAdCampaignsInsights = async (campaignId) => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/google/campaigns/insights',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
          campaign_id: campaignId,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
      });
      if (res.success) {
        setGoogleAdCampaignInsights((prevInsights) => ({
          ...prevInsights,
          [campaignId]: res.data.length > 0 && res.data[0].results.length > 0 ? res.data[0].results[0] : {},
        }));
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ad Campaigns Insights:', error);
    }
  };

  const getGoogleAdGroups = async (p = {}, loading = false) => {
    try {
      loader(loading);
      const res = await ApiClient.getAll({
        url: 'api/google/adgroups',
        params: {
          access_token: accessToken,
          customer_id: adAccountId,
          ...GoogleAdFilter,
          ...p
        },
      });
      if (res.success) {
        if (res.data.length > 0 && res.data[0].results.length > 0) {
          setGoogleAdGroups(res.data[0].results?.sort((a, b) => b?.metrics.impressions - a?.metrics.impressions));
        } else {
          setGoogleAdGroups([]);
        }
        loader(false)
      } else {
        loader(false);
      }
    } catch (error) {
      loader(false);
      console.error('Error fetching Google Ad Groups:', error);
    }
  };


  const ExportGoogleAdsGroup = async () => {
    loader(true)
    const token = await localStorage.getItem("token");
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };
    const req = await axios({
      method: "get",
      url: `${environment.api}api/facebook/campaigns/adsets?access_token=${accessToken}&campaign_id=${campaignId}&time_range=${JSON.stringify(timeRange)}&download=yes`,
      responseType: "blob",
      body: { token: token },
    });
    let blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `GoogleAdsGroup.xlsx`;
    loader(false)
    link.click();
  }

  const ExportGoogleCampaigns = async () => {
    loader(true)
    const token = await localStorage.getItem("token");
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };
    const req = await axios({
      method: "get",
      url: `${environment.api}api/google/campaigns?access_token=${accessToken}&customer_id=${adAccountId}&start_date=${filters.startDate}&end_date=${filters.endDate}&download=yes`,
      responseType: "blob",
      body: { token: token },
    });
    let blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `GoogleCampaigns.xlsx`;
    loader(false)
    link.click();
  }

  useEffect(() => {
    const fetchApiData = async () => {
      try {

        const res = await ApiClient.get(`api/connected/ads/accounts`, { platform: "google" }, '', '', { apiCall: true });

        if (res.success) {
          if (!res?.data?.length) {
            history(`/marketing-analytics`);
          } else {
            const googleToken = res?.data?.length > 0 ? res?.data[0]?.google_access_token : {};
            setAccessToken(googleToken);  // Update accessToken, triggering the next useEffect
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchApiData();
  }, []);

  useEffect(() => {
    if (accessToken) {
      getGoogleAdsAccounts();
    }
  }, [accessToken]);

  const resetGoogleAdsData = () => {
    setAdAccountYear("current_year");
    setGoogleAdAccountInfo({});
    setGoogleAdAccountInsights({});
    setGoogleAdCampaigns([]);
    setGoogleAdCampaignInsights([]);
    setGoogleAdGroups([]);
    setGoogleAdAccountDeviceInsights([]);
  }

  useEffect(() => {
    if (adAccountId) {
      resetGoogleAdsData();
      getGoogleAdAcountInfo();
      getGoogleAdAccountInsights();
      getGoogleAdCampaigns();
      getGoogleAdGroups();
      getGoogleAdAccountInsights("segments.device");
    }
  }, [adAccountId, filters]);

  return (
    <div>
      <Layout>
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-col gap-1">
            <h4 className="text-2xl font-semibold text-[#3C3E49]">
              Google Ads
            </h4>
            <p className="text-sm font-normal text-[#75757A]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>
          </div>
          <div className="flex gap-2">
            <SelectDropdown
              displayValue="name"
              placeholder="Select Google Ad Account"
              showPlaceHolder={false}
              options={adAccountsOptions}
              intialValue={adAccountId}
              result={(e) => {
                setAdAccountId(e.value);
              }}
            />
            <DateRangePicker
              disabled={filters.yearType === "calendar"}
              title={
                filters.yearType === "calendar"
                  ? ""
                  : "Change Data by Calendar Year to change dates"
              }
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare: filters.compare,
                compareStart: filters.compareStart,
                compareEnd: filters.compareEnd,
              }}
              isCompare={true}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-6">
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Total Impression" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{pipeModel.number(googleAdAccountInsights?.metrics?.impressions || 0)}</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Total Clicks" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{pipeModel.number(googleAdAccountInsights?.metrics?.clicks || 0)}</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="CTR" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{parseFloat(googleAdAccountInsights?.metrics?.ctr || 0).toFixed(2) || 0}%</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Conversion" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{pipeModel.number(googleAdAccountInsights?.metrics?.allConversions || 0)}</h2>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Average CPM" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{pipeModel.currency(googleAdAccountInsights?.metrics?.averageCp || 0, '$', user?.companyCurrencyFormat)}</h2>
              <img src="/assets/img/svgs/graph-down.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Average Cost" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{pipeModel.currency(googleAdAccountInsights?.metrics?.averageCost || 0, '$', user?.companyCurrencyFormat)}</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Average CPC" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{pipeModel.currency(googleAdAccountInsights?.metrics?.averageCpc || 0, '$', user?.companyCurrencyFormat)}</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
          <div className="p-4 flex flex-col gap-8 rounded-large bg-white shadow-box border border-grey">
            <Badge label="Average CPE" />
            <div className="flex justify-between gap-4 items-center">
              <h2 className="text-typo text-2xl font-medium mb-2.5">{pipeModel.currency(googleAdAccountInsights?.metrics?.averageCpe || 0, '$', user?.companyCurrencyFormat)}</h2>
              <img src="/assets/img/svgs/graphdown-green.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-7 mb-6">
          <div className="shadow-box border border-grey bg-white rounded-large w-full">
            <h1 className="text-typo text-lg font-semibold p-4">Device</h1>
            <DeviceRoundGraph googleAdInsightsByDevice={googleAdAccountDeviceInsights || []} />
          </div>
          {/*<div className="shadow-box border border-grey bg-white rounded-large w-full">
            <h1 className="text-typo text-lg font-semibold p-4">Device</h1>
            <DeviceRoundGraph googleAdInsightsByDevice={googleAdAccountDeviceInsights || []} />
          </div>*/}
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Campaigns
            </h1>
            <div>
              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={ExportGoogleCampaigns}
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Impr.
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Clicks
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CTR
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg.CPC
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg.CPM
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Budget
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Bidding Strategy Type
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Start Date
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    End Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {googleAdCampaigns.map((campaign) => (
                  <tr key={campaign.campaign.id}>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                      {campaign.campaign.name}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {pipeModel.number(campaign.metrics.impressions || 0)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {pipeModel.number(campaign.metrics.clicks || 0)}
                    </td>
                    <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                      {parseFloat(campaign.metrics.ctr || 0).toFixed(2) || 0}%
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {pipeModel.currency(campaign.metrics.averageCpc || 0, '$', user?.companyCurrencyFormat)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                      {pipeModel.currency(campaign.metrics.averageCpm || 0, '$', user?.companyCurrencyFormat)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                      {pipeModel.currency(campaign.campaignBudget.amountMicros || 0, '$', user?.companyCurrencyFormat)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0] capitalize">
                      {campaign.campaign.biddingStrategyType?.toLowerCase() || '--'}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {datepipeModel.date(campaign.campaign.startDate, user?.companyDateFormat)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 border-r-0 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {datepipeModel.date(campaign.campaign.endDate, user?.companyDateFormat)}
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>

        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Campaigns Insights
            </h1>
            <div>
              <div className="flex gap-4">
                <button
                  type="button"
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Impr.
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Clicks
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CTR
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg. CPC
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg. Cost
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg. CPM
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Conversions
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(googleAdCampaignInsights)?.sort((a, b) => {
                  // Access the impressions for sorting
                  const impressionsA = googleAdCampaignInsights[a]?.metrics?.impressions || 0;
                  const impressionsB = googleAdCampaignInsights[b]?.metrics?.impressions || 0;

                  // Sort in descending order
                  return impressionsB - impressionsA; // Change to impressionsA - impressionsB for ascending order
                })
                  .map((campaignId, index) => {
                    const campaignData = googleAdCampaignInsights[campaignId];
                    return (
                      <tr key={index}>
                        <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {campaignData.campaign.name}
                        </td>
                        <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {pipeModel.number(campaignData.metrics.impressions || 0)}
                        </td>
                        <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {pipeModel.number(campaignData.metrics.clicks || 0)}
                        </td>
                        <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {parseFloat(campaignData.metrics.ctr || 0).toFixed(2) || 0}%
                        </td>
                        <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {pipeModel.currency(campaignData.metrics.averageCpc || 0, '$', user?.companyCurrencyFormat)}
                        </td>
                        <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                          {pipeModel.currency(campaignData.metrics.averageCost || 0, '$', user?.companyCurrencyFormat)}
                        </td>
                        <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {pipeModel.currency(campaignData.metrics.averageCpm || 0, '$', user?.companyCurrencyFormat)}
                        </td>
                        <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {pipeModel.number(campaignData.metrics.allConversions || 0)}
                        </td>
                        <td className=" text-typo cursor-pointer px-3.5 border-r-0 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0] capitalize">
                          {campaignData.campaign.status?.toLowerCase()}
                        </td>
                      </tr>
                    );
                  })}

              </tbody>
            </table>
          </div>
        </div>

        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Ad Groups
            </h1>
            <div>
              <div className="flex gap-4">
                <SelectDropdown
                  result={e => {
                    setGoogleAdFilter(prev => ({ ...prev, status: e?.value }))
                    getGoogleAdGroups({ status: e?.value }, true)
                  }}
                  intialValue={GoogleAdFilter?.status}
                  options={[{ id: 'PAUSED', name: 'Paused' }, { id: 'ENABLED', name: 'Enabled' }]}
                  className="Select Status"

                />

                <SelectDropdown
                  result={e => {
                    setGoogleAdFilter(prev => ({ ...prev, campaign_id: e?.value }))
                    getGoogleAdGroups({ campaign_id: e?.value }, true)
                  }}
                  intialValue={GoogleAdFilter?.campaign_id}
                  options={googleAdCampaigns?.map((item) => item?.campaign)}
                  placeholder="Select Campaign"

                />

                <button
                  type="button"
                  onClick={e => {
                    setGoogleAdFilter(prev => ({ ...prev, status: '', campaign_id: '' }))
                    getGoogleAdGroups({ status: '', campaign_id: '' }, true)
                  }}
                  className="relative bg-primary text-white cursor-pointer text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-primary transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed "
                >
                  Reset                </button>
                <button
                  type="button"
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign Name
                  </th>
                  <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Ad Name
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Impr.
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Clicks
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CPC
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Conversions
                  </th>
                  <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg. CPC
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Avg. CPM
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Type
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {googleAdGroups.map((adGroup) => (
                  <tr key={adGroup.adGroup.id}>

                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                      {adGroup.campaign.name}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                      {adGroup.adGroup.name}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {pipeModel.number(adGroup.metrics.impressions || 0)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {pipeModel.number(adGroup.metrics.clicks || 0)}
                    </td>
                    <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                      {pipeModel.currency(adGroup.metrics.costPerConversion || 0, '$', user?.companyCurrencyFormat)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {pipeModel.number(adGroup.metrics.allConversions || 0)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                      {pipeModel.currency(adGroup.metrics.averageCpc, '$', user?.companyCurrencyFormat)}
                    </td>
                    <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                      {pipeModel.currency(adGroup.metrics.averageCpm || 0, '$', user?.companyCurrencyFormat)}
                    </td>
                    <td className=" text-typo cursor-pointer capitalize px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {adGroup.adGroup.type?.toLowerCase()}
                    </td>
                    <td className=" text-typo cursor-pointer capitalize px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {adGroup.adGroup.status?.toLowerCase()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!googleAdGroups?.length ? <div className="text-center p-4">No Data </div> : null}
          </div>
        </div>

      </Layout>
      ;
    </div>
  );
};

export default GoogleAds;
