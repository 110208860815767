import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const PieCircleChart = () => {
  const user = useSelector((state) => state.user);

  const regionData = {
    california: 40,
    newYork: 15,
    texas: 20,
    england: 10,
    washington: 15,
  };

  const value = [
    {
      name: "California",
      value: regionData?.california || 0,
    },
    {
      name: "New York",
      value: regionData?.newYork || 0,
    },
    {
      name: "Texas",
      value: regionData?.texas || 0,
    },
    {
      name: "England",
      value: regionData?.england || 0,
    },
    {
      name: "Washington",
      value: regionData?.washington || 0,
    },
  ];

  let updatedOption = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c}% ({d}%)",
    },
    legend: {
      orient: "vertical",
      right: 1,
      top: "center",
      icon: "circle",
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#111827',
      },
      itemGap: 15, 
      itemWidth: 10,
      itemHeight: 10,
      data: value.map((v) => v.name),
    },
    series: [
      {
        name: "Region Distribution",
        type: "pie",
        radius: ["0%", "75%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        },
        data: value.map((v) => ({
          name: v.name,
          value: v.value,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        color: ["#8064FF", "#3DBDFF", "#FDBB2B", "#FF5C5C", "#FF53CD"],
        left: "0%",
        center: ["40%", "50%"],
      },
    ],
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default PieCircleChart;
