import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const LineCurveTwobarGraph = () => {
  const user = useSelector((state) => state.user);

  const currentYearData = [
    { month: "Jan", value: 4000 },
    { month: "Feb", value: 6000 },
    { month: "Mar", value: 4500 },
    { month: "Apr", value: 5500 },
    { month: "May", value: 6500 },
    { month: "Jun", value: 8000 },
    { month: "Jul", value: 7000 },
    { month: "Aug", value: 8500 },
    { month: "Sep", value: 6000 },
    { month: "Oct", value: 5000 },
    { month: "Nov", value: 6000 },
    { month: "Dec", value: 4000 },
  ];

  const previousYearData = [
    { month: "Jan", value: 5000 },
    { month: "Feb", value: 7000 },
    { month: "Mar", value: 5500 },
    { month: "Apr", value: 6500 },
    { month: "May", value: 7500 },
    { month: "Jun", value: 9000 },
    { month: "Jul", value: 8000 },
    { month: "Aug", value: 9000 },
    { month: "Sep", value: 7000 },
    { month: "Oct", value: 6000 },
    { month: "Nov", value: 7000 },
    { month: "Dec", value: 5000 },
  ];

  let updatedOption = {
    tooltip: {
      trigger: "axis",
      backgroundColor: '#fff',
      borderColor: '#E2E7ED',
      borderWidth: 1,
      padding: [10, 15],
      textStyle: {
        color: '#374151',
      },
      formatter: function (params) {
        let tooltipTitle = `<div style="font-weight: bold; font-size: 14px; margin-bottom: 5px;color:#1D2433;">Sales Revenue | ${params[0].name}</div>`;
        
        let tooltipContent = params.map((item, index) => {
          let dotColor = index === 0 ? "#FAAC14" : "#705EE6"; 
          let textColor = index === 0 ? "#6B7280" : "#6B7280";           
          return `<div style="display: flex; align-items: center; margin-top: 4px;">
                    <span style="display: inline-block; width: 8px; height: 8px; background-color: ${dotColor}; border-radius: 50%; margin-right: 5px;"></span>
                    <span style="font-size: 14px; font-weight: 500; color: ${textColor};">${item.seriesName}: <b style="font-weight:bold;color:#1D2433;">${item.value}</b></span>
                  </div>`;
        }).join('');
        
        return `${tooltipTitle} ${tooltipContent}`;
      },
    },
    legend: {
      data: ["Users", "Users (previous year)"],
      top: "5%",
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: "#6B7280",
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: currentYearData.map((item) => item.month),
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 14, 
        fontWeight: 500,
        color: "#92929D", 
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => `${value / 1000}k`,
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLabel: {
        fontSize: 14,
        fontWeight: 500,
        color: "#92929D", 
        margin: 20, 
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    series: [
      {
        name: "Users",
        type: "line",
        smooth: true,
        data: currentYearData.map((item) => item.value),
        itemStyle: {
          color: "#705EE6",
        },
        lineStyle: {
          color: "#705EE6",
          width: 2,
        },
        symbol: "circle",
        symbolSize: 8,
        areaStyle: {
          color: "rgba(111, 66, 193, 0.1)",
          origin: "start",
        },
      },
      {
        name: "Users (previous year)",
        type: "line",
        smooth: true,
        data: previousYearData.map((item) => item.value),
        itemStyle: {
          color: "#FAAC14",
        },
        lineStyle: {
          color: "#FAAC14",
          width: 2,
        },
        symbol: "circle",
        symbolSize: 8,
        areaStyle: {
          color: "rgba(240, 196, 25, 0.1)",
          origin: "start",
        },
      },
    ],
    visualMap: [
      {
        show: false,
        dimension: 0,
        pieces: [{ gt: 5, lt: 6, color: "rgba(240, 196, 25, 0.5)" }],
      },
    ],
    markLine: {
      symbol: "none",
      data: [
        {
          xAxis: 5,
          lineStyle: {
            type: "dashed",
            color: "#F0C419",
          },
        },
      ],
    },
  };
  

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default LineCurveTwobarGraph;
