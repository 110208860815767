import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const VerticalBarGraph = ({ colors = ["#6956E5", "#B0A5F5", "#5ADF91"] }) => {
  const user = useSelector((state) => state.user);

  const salesData = {
    January: { Desktop: 10, Mobile: 5, Tablet: 2 },
    February: { Desktop: 12, Mobile: 6, Tablet: 4 },
    March: { Desktop: 13, Mobile: 6, Tablet: 5 },
    April: { Desktop: 15, Mobile: 7, Tablet: 3 },
    May: { Desktop: 14, Mobile: 6, Tablet: 4 },
    June: { Desktop: 16, Mobile: 6, Tablet: 5 },
  };

  const months = Object.keys(salesData);
  const platforms = ["Desktop", "Mobile", "Tablet"];

  const updatedOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: platforms,
    },
    xAxis: {
      type: "category",
      data: months,
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    series: platforms.map((platform) => ({
      name: platform,
      type: "bar",
      stack: "total",
      label: {
        show: false,
      },
      data: months.map((month) => salesData[month][platform]),
      itemStyle: {
        borderRadius: [4, 4, 0, 0],
      },
      barWidth: "20%",
    })),
    color: colors,
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default VerticalBarGraph;
