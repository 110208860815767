import React, { useState } from "react";
import Layout from "../../components/global/layout";
import Badge from "../../components/Badge";
import { HiMinusSmall, HiPlusSmall } from "react-icons/hi2";
import SelectDropdown from "../../components/common/SelectDropdown";
import LineCurveGraph from "./GraphCharts/LineCurveGraph";
import { IoIosTrendingUp } from "react-icons/io";
import { AiOutlineDownload } from "react-icons/ai";
import DateRangePicker from "../../components/common/DateRangePicker";
import { HiOutlineSearch } from "react-icons/hi";
import datepipeModel from "../../models/datepipemodel";

const AdPerformance = () => {
  const options = [
    { id: "Line", name: "Line Chart" },
    { id: "Bar", name: "Bar Chart" },
  ];
  const months = [
    { id: "month", name: "January" },
    { id: "month", name: "February" },
  ];
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    sortBy: "dateCreated desc",
    search: "",
    posId: "",
    status: "",
    productCode: "",
    category: [],
    travelStartDate: "",
    travelEndDate: "",
    reseller: "",
    third_party_delete: "",
    startDate: datepipeModel.datetostring(new Date()),
    endDate: datepipeModel.datetostring(new Date()),
    compare: false,
    compareStart: "",
    compareEnd: "",
  });
  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    };

    if (
      datepipeModel.datetostring(e.startDate) ===
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      setFilter({ ...filters, ...f });
      dataFilter({ ...f });
    }
  };
  return (
    <Layout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-semibold text-[#3C3E49]">
            Ad Performance
          </h4>
          <p className="text-sm font-normal text-[#75757A]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit,
            voluptas.
          </p>
        </div>
        <DateRangePicker
          disabled={filters.yearType == "calendar" ? true : false}
          title={
            filters.yearType == "calendar"
              ? ""
              : "Change Data by Calendar Year to change dates"
          }
          value={{
            startDate: filters.startDate,
            endDate: filters.endDate,
            compare: filters.compare,
            compareStart: filters.compareStart,
            compareEnd: filters.compareEnd,
          }}
          isCompare={true}
          onChange={(e) => onRangeChange(e)}
          fullWidth={false}
        />
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-6">
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="Impressions" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            $19,366.00
          </h1>
          <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
            <HiPlusSmall className="text-[#E92531]" />
            244.9%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="Reach" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            44,547
          </h1>
          <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
            <HiPlusSmall className="text-[#047857]" />
            88.2%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="Clicks" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            7,430
          </h1>
          <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
            <HiPlusSmall className="text-[#047857]" />
            16.3%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="CTR" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            5.07%
          </h1>
          <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
            <HiPlusSmall className="text-[#047857]" />
            162.5%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="CPC" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            $3,208.40
          </h1>
          <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
            <HiPlusSmall className="text-[#E92531]" />
            52.3%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="Conversions (Website Purchases)" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            44,547
          </h1>
          <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
            <HiMinusSmall className="text-[#E92531]" />
            70.2%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="Cost Per Conversion" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            2,543
          </h1>
          <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
            <HiPlusSmall className="text-[#047857]" />
            7.1%
          </div>
        </div>
        <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
          <Badge label="Cost Per Lead" />
          <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
            $330.95
          </h1>
          <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
            <HiPlusSmall className="text-[#047857]" />
            3.4%
          </div>
        </div>
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
        <div className="flex justify-between gap-4 items-center p-5">
          <div className="">
            <h1 className="text-typo text-lg font-semibold">
              Account Overview
            </h1>
            <div className="flex gap-2 items-center">
              <div className="flex w-fit rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
                <IoIosTrendingUp className="text-[#047857]" />
                2.11%
              </div>
              <p className="text-sm font-medium text-[#6B7280]">
                Compare to previous month
              </p>
            </div>
          </div>
          <div className="flex gap-2">
            <SelectDropdown
              displayValue="name"
              placeholder="Select Chart"
              options={options}
              noDefault={true}
            />
            <SelectDropdown
              displayValue="name"
              placeholder="This Month"
              options={months}
              noDefault={true}
            />
          </div>
        </div>
        <LineCurveGraph />
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large mb-6">
        <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
          <h1 className="text-typo text-lg font-bold">Campaign Performance</h1>
          <div className="flex gap-4">
            <form className="relative max-w-[250px] w-full">
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
              <input
                className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                placeholder="Search by name"
              />
            </form>
            <DateRangePicker
              disabled={filters.yearType == "calendar" ? true : false}
              title={
                filters.yearType == "calendar"
                  ? ""
                  : "Change Data by Calendar Year to change dates"
              }
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare: filters.compare,
                compareStart: filters.compareStart,
                compareEnd: filters.compareEnd,
              }}
              isCompare={true}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
            />
            <button
              type="button"
              className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
            >
              <AiOutlineDownload className="text-typo text-xl" />
              Download CSV
            </button>
          </div>
        </div>
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="">
              <tr className="w-full">
                <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Campaign Name
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Amount Spent
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Reach
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Clicks
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  CTR
                </th>
                <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  CPC
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Impressions
                </th>
                <th className="text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Leads
                </th>
                <th className="text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Cost Per Lead
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                  jewelry
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  $19,366.00
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  44,547
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  7,430
                </td>
                <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                  5.07%
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  $3,208.40
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                  44,547
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  2,543
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 border-r-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  $330.95
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="shadow-box border border-grey bg-white rounded-large mb-6">
        <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
          <h1 className="text-typo text-lg font-bold">Campaign Performance</h1>
          <div className="flex gap-4">
            <form className="relative max-w-[250px] w-full">
              <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
              <input
                className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                placeholder="Search by name"
              />
            </form>
            <DateRangePicker
              disabled={filters.yearType == "calendar" ? true : false}
              title={
                filters.yearType == "calendar"
                  ? ""
                  : "Change Data by Calendar Year to change dates"
              }
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare: filters.compare,
                compareStart: filters.compareStart,
                compareEnd: filters.compareEnd,
              }}
              isCompare={true}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
            />
            <button
              type="button"
              className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
            >
              <AiOutlineDownload className="text-typo text-xl" />
              Download CSV
            </button>
          </div>
        </div>
        <div className="scrollbar w-full overflow-auto">
          <table className="w-full">
            <thead className="">
              <tr className="w-full">
                <th className=" text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Campaign Name
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Campaign Objective
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Budget
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Impr.
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Reach
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Clicks
                </th>
                <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  CTR
                </th>
                <th className=" text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  CPC
                </th>
                <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                  Amount Spent
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                  --
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  jewelry
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  $ 1,422.40
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  4,282,601
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  604,657
                </td>
                <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                  71,353
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  63.4%
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                  $ 3,239.40
                </td>
                <td className=" text-typo cursor-pointer px-3.5 text-sm border-b-0 border-r-0 font-normal !py-4 border text-center border-[#EAECF0]">
                  $ 102,430.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default AdPerformance;
