export const MonthsData = [
    {
        "id": "1",
        "name": "January"
    },
    {
        "id": "2",
        "name": "February"
    },
    {
        "id": "3",
        "name": "March"
    },
    {
        "id": "4",
        "name": "April"
    },
    {
        "id": "5",
        "name": "May"
    },
    {
        "id": "6",
        "name": "June"
    },
    {
        "id": "7",
        "name": "July"
    },
    {
        "id": "8",
        "name": "August"
    },
    {
        "id": "9",
        "name": "September"
    },
    {
        "id": "10",
        "name": "October"
    },
    {
        "id": "11",
        "name": "November"
    },
    {
        "id": "12",
        "name": "December"
    }
]


export const yearsList = [
    { "name": "2010", "id": 2010 },
    { "name": "2011", "id": 2011 },
    { "name": "2012", "id": 2012 },
    { "name": "2013", "id": 2013 },
    { "name": "2014", "id": 2014 },
    { "name": "2015", "id": 2015 },
    { "name": "2016", "id": 2016 },
    { "name": "2017", "id": 2017 },
    { "name": "2018", "id": 2018 },
    { "name": "2019", "id": 2019 },
    { "name": "2020", "id": 2020 },
    { "name": "2021", "id": 2021 },
    { "name": "2022", "id": 2022 },
    { "name": "2023", "id": 2023 },
    { "name": "2024", "id": 2024 },
    { "name": "2025", "id": 2025 },
    { "name": "2026", "id": 2026 },
    { "name": "2027", "id": 2027 },
    { "name": "2028", "id": 2028 },
    { "name": "2029", "id": 2029 },
    { "name": "2030", "id": 2030 },
    { "name": "2031", "id": 2031 },
    { "name": "2032", "id": 2032 },
    { "name": "2033", "id": 2033 },
    { "name": "2034", "id": 2034 },
    { "name": "2035", "id": 2035 },
    { "name": "2036", "id": 2036 },
    { "name": "2037", "id": 2037 },
    { "name": "2038", "id": 2038 },
    { "name": "2039", "id": 2039 },
    { "name": "2040", "id": 2040 },
    { "name": "2041", "id": 2041 },
    { "name": "2042", "id": 2042 },
    { "name": "2043", "id": 2043 },
    { "name": "2044", "id": 2044 },
    { "name": "2045", "id": 2045 },
    { "name": "2046", "id": 2046 },
    { "name": "2047", "id": 2047 },
    { "name": "2048", "id": 2048 },
    { "name": "2049", "id": 2049 },
    { "name": "2050", "id": 2050 }
]

export const FutureYearList = [
    { "name": "2024", "id": 2024 },
    { "name": "2025", "id": 2025 },
    { "name": "2026", "id": 2026 },
    { "name": "2027", "id": 2027 },
    { "name": "2028", "id": 2028 },
    { "name": "2029", "id": 2029 },
    { "name": "2030", "id": 2030 },
    { "name": "2031", "id": 2031 },
    { "name": "2032", "id": 2032 },
    { "name": "2033", "id": 2033 },
    { "name": "2034", "id": 2034 },
    { "name": "2035", "id": 2035 },
    { "name": "2036", "id": 2036 },
    { "name": "2037", "id": 2037 },
    { "name": "2038", "id": 2038 },
    { "name": "2039", "id": 2039 },
    { "name": "2040", "id": 2040 },
    { "name": "2041", "id": 2041 },
    { "name": "2042", "id": 2042 },
    { "name": "2043", "id": 2043 },
    { "name": "2044", "id": 2044 },
    { "name": "2045", "id": 2045 },
    { "name": "2046", "id": 2046 },
    { "name": "2047", "id": 2047 },
    { "name": "2048", "id": 2048 },
    { "name": "2049", "id": 2049 },
    { "name": "2050", "id": 2050 },
    { "name": "2051", "id": 2051 },
    { "name": "2052", "id": 2052 },
    { "name": "2053", "id": 2053 },
    { "name": "2054", "id": 2054 },
    { "name": "2055", "id": 2055 },
    { "name": "2056", "id": 2056 },
    { "name": "2057", "id": 2057 },
    { "name": "2058", "id": 2058 },
    { "name": "2059", "id": 2059 },
    { "name": "2060", "id": 2060 },
    { "name": "2061", "id": 2061 },
    { "name": "2062", "id": 2062 },
    { "name": "2063", "id": 2063 },
    { "name": "2064", "id": 2064 },
    { "name": "2065", "id": 2065 },
    { "name": "2066", "id": 2066 },
]
