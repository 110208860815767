import { useState, useEffect, useRef } from "react";
import Layout from "../../components/global/layout";
import Badge from "../../components/Badge";
import { HiOutlineSearch } from "react-icons/hi";
import { HiMinusSmall, HiPlusSmall } from "react-icons/hi2";
import DateRangePicker from "../../components/common/DateRangePicker";
import { AiOutlineDownload } from "react-icons/ai";
import { IoIosTrendingUp } from "react-icons/io";
import FbLineCurveGraph from "./GraphCharts/FbLineCurveGraph";
import AgeLineBarGraph from "./GraphCharts/AgeLineBarGraph";
import GenderRoundGraph from "./GraphCharts/GenderRoundGraph";
import DeviceRoundGraph from "./GraphCharts/FbDeviceRoundGraph";
import PublisherPlatformLineBarGraph from "./GraphCharts/PublisherPlatformLineBarGraph";
import HorizontalLineGraph from "./GraphCharts/HorizontalLineGraph";
import SelectDropdown from "../../components/common/SelectDropdown";
import datepipeModel from "../../models/datepipemodel";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useNavigate } from "react-router-dom"
import environment from "../../environment";
import axios from "axios";

const FacebookAds = () => {

  const history = useNavigate();
  const [adAccountsOptions, setAdAccountsOptions] = useState([]);
  const [adAccountId, setAdAccountId] = useState(null);
  const [fbUserData, setFbUserData] = useState();
  const [fbAdAccountData, setFbAdAcountData] = useState();
  const [fbAdsInsights, setFbAdInsights] = useState({});
  const [fbAdInsightsYearly, setFbAdInsightsYearly] = useState({});
  const [fbAdInsightsByAge, setFbAdInsightsByAge] = useState({});
  const [fbAdInsightsByRegion, setFbAdInsightsByRegion] = useState({});
  const [fbAdInsightsByGender, setFbAdInsightsByGender] = useState({});
  const [fbAdInsightsByDevice, setFbAdInsightsByDevice] = useState({});
  const [fbAdInsightsByPublisherPlatform, setFbAdInsightsByPublisherPlatform] = useState({});
  const [fbAdCampaigns, setFbAdCampaigns] = useState([]);
  const [fbAdCampaignInsights, setFbAdCampaignInsights] = useState({});
  const [fbCampaignAdSets, setFbCampaignAdSets] = useState({});
  const [fbAdSetInsights, setFbAdSetInsights] = useState({});
  const [adAccountYear, setAdAccountYear] = useState("current_year");
  const [accessToken, setAccessToken] = useState(null)
const [AccountDetails,setAccountDetails]=useState({});

  const years = [
    { id: "current_year", name: "This Year" },
    { id: "last_year", name: "Last Year" },
  ];
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    sortBy: "dateCreated desc",
    search: "",
    posId: "",
    status: "",
    productCode: "",
    category: [],
    travelStartDate: "",
    travelEndDate: "",
    reseller: "",
    third_party_delete: "",
    startDate: datepipeModel.datetostring(new Date()),
    endDate: datepipeModel.datetostring(new Date()),
    compare: false,
    compareStart: "",
    compareEnd: "",
  });
  const onRangeChange = (e) => {
    let f = {
      startDate: datepipeModel.datetostring(e.startDate),
      endDate: datepipeModel.datetostring(e.endDate),
      compare: e.compare,
      compareStart: e.compareStart,
      compareEnd: e.compareEnd,
    };

    if (
      datepipeModel.datetostring(e.startDate) ===
      datepipeModel.datetostring(e.endDate)
    ) {
      setFilter({ ...filters, ...f });
    } else {
      setFilter({ ...filters, ...f });
    }
  };

  const resetFbAdData = () => {
    setAdAccountYear("current_year");
    setFbAdInsights({});
    setFbAdInsightsYearly({});
    setFbAdInsightsByAge({});
    setFbAdInsightsByRegion({});
    setFbAdInsightsByGender({});
    setFbAdInsightsByDevice({});
    setFbAdInsightsByPublisherPlatform({});
    setFbAdCampaigns([]);
    setFbAdCampaignInsights({});
    setFbCampaignAdSets({});
    setFbAdSetInsights({});
  };

  const getFbUserData = async () => {
    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/me',
        params: { access_token: accessToken },
      });

      if (res.success) {

        setFbUserData(res.data);
        const adAccountsData = res.data.adaccounts.data;
        setAdAccountsOptions(adAccountsData);
        if (res.data.adaccounts.data.length > 0) {
          setAdAccountId(res.data.adaccounts.data[0].id);
        }
      }
    } catch (error) {
      console.error('Error fetching Facebook user data:', error);
    }
  };

  const getFbAdAcountData = async () => {
    loader(true);
    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/ad-account',
        params: {
          access_token: accessToken,
          fb_ad_account_id: adAccountId,
        },
      });

      if (res.success) {
        setFbAdAcountData(res.data);
      } else {
        loader(false);
      }
    } catch (error) {
      console.error('Error fetching Facebook Ad Account data:', error);
      loader(false);
    }
  };

  const getFbAdInsights = async () => {
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };

    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/ad-account/insights',
        params: {
          access_token: accessToken,
          fb_ad_account_id: adAccountId,
          time_range: JSON.stringify(timeRange),
        },
      });

      if (res.success) {
        setFbAdInsights(res.data);
      } else {
        loader(false);
      }
    } catch (error) {
      console.error('Error fetching Facebook Ad Insights:', error);
      loader(false);
    }
  };

  const getFbAdInsightsYearly = async (year) => {
    let timeRange;

    if (year === "current_year") {
      timeRange = {
        since: `${new Date().getFullYear()}-01-01`,
        until: `${new Date().getFullYear()}-12-31`,
      };
    } else if (year === "last_year") {
      const lastYear = new Date().getFullYear() - 1;
      timeRange = {
        since: `${lastYear}-01-01`,
        until: `${lastYear}-12-31`,
      };
    }

    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/ad-account/insights',
        params: {
          access_token: accessToken,
          fb_ad_account_id: adAccountId,
          time_increment: 30, // 30 days
          time_range: JSON.stringify(timeRange),
        },
      });

      if (res.success) {
        setFbAdInsightsYearly(res.data);
      } else {
        loader(false);
      }
    } catch (error) {
      console.error('Error fetching Facebook Ad Insights:', error);
      loader(false);
    }
  };


  const getAdAccountInsightsByBreakdown = async (breakdown) => {
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };

    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/ad-account/insights-breakdown',
        params: {
          access_token: accessToken,
          fb_ad_account_id: adAccountId,
          breakdown: breakdown,
          time_range: JSON.stringify(timeRange),
        },
      });

      if (res.success) {
        switch (breakdown) {
          case 'age':
            setFbAdInsightsByAge(res.data);
            break;
          case 'region':
            setFbAdInsightsByRegion(res.data);
            break;
          case 'gender':
            setFbAdInsightsByGender(res.data);
            break;
          case 'device_platform':
            setFbAdInsightsByDevice(res.data);
            break;
          case 'publisher_platform':
            setFbAdInsightsByPublisherPlatform(res.data);
            break;
          default:
            console.warn('Unknown breakdown:', breakdown);
        }
      } else {
        loader(false);
      }
    } catch (error) {
      console.error(`Error fetching ad account insights by ${breakdown}:`, error);
      loader(false);
    }
  };

  const getFbAdCampaigns = async () => {
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };

    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/campaigns',
        params: {
          access_token: accessToken,
          fb_ad_account_id: adAccountId,
          time_range: JSON.stringify(timeRange),
        },
      });

      if (res.success) {
        setFbAdCampaigns(res.data.data);

        // Loop through each campaign and await insights and ad sets
        for (const campaign of res.data.data) {
          getFbAdCampaignInsights(campaign.id);
          getFbCampaignAdSets(campaign.id);
        }
      } else {
        loader(false);
      }
    } catch (error) {
      console.error('Error fetching ad campaigns:', error);
      loader(false);
    } finally {
      loader(false);
    }
  };

  const getFbAdCampaignInsights = async (campaignId) => {
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };

    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/campaigns/insights',
        params: {
          access_token: accessToken,
          campaign_id: campaignId,
          time_range: JSON.stringify(timeRange),
        },
      });

      if (res.success) {
        setFbAdCampaignInsights((prevInsights) => ({
          ...prevInsights,
          [campaignId]: res.data,
        }));
      } else {
        loader(false);
      }
    } catch (error) {
      console.error('Error fetching campaign insights:', error);
      loader(false);
    }
  };

  const getFbCampaignAdSets = async (campaignId) => {
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };

    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/campaigns/adsets',
        params: {
          access_token: accessToken,
          campaign_id: campaignId,
          time_range: JSON.stringify(timeRange),
        },
      });

      if (res.success) {
        setFbCampaignAdSets((prevInsights) => ({
          ...prevInsights,
          [campaignId]: res.data,
        }));

        // Iterate through each ad set and await insights
        for (const adSet of res.data.data) {
          getFbAdSetInsights(adSet.id);
        }
      } else {
        loader(false);
      }
    } catch (error) {
      console.error('Error fetching campaign ad sets:', error);
      loader(false);
    }
  };

  const getFbAdSetInsights = async (adSetId) => {
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };

    try {
      const res = await ApiClient.getAll({
        url: 'api/facebook/adsets/insights',
        params: {
          access_token: accessToken,
          ad_set_id: adSetId,
          time_range: JSON.stringify(timeRange),
        },
      });

      if (res.success) {
        setFbAdSetInsights((prevInsights) => ({
          ...prevInsights,
          [adSetId]: res.data,
        }));
      } else {
        loader(false);
      }
    } catch (error) {
      console.error('Error fetching ad set insights:', error);
      loader(false);
    }
  };

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const res = await ApiClient.get(`api/connected/ads/accounts`, { platform: "facebook" }, '', '', { apiCall: true });

        if (res.success) {
          if (!res?.data?.length) {
            history(`/marketing-analytics`);
          } else {
            const fbToken = res?.data?.length > 0 ? res?.data[0]?.facebook_long_lived_token : {};
            setAccountDetails(res?.data?.[0])
            setAccessToken(fbToken);  // Update accessToken, triggering the next useEffect
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchApiData();
  }, []);

  // useEffect to call getFbUserData when accessToken is set
  useEffect(() => {
    const fetchFbData = async () => {
      if (accessToken) {
        await getFbUserData();  // Only invoke if accessToken exists
      }
    };

    fetchFbData();
  }, [accessToken]);  // This effect runs whenever accessToken is updated


  useEffect(() => {
    if (adAccountId) {
      resetFbAdData();
      getFbAdAcountData();
      getFbAdInsights();
      getAdAccountInsightsByBreakdown("age");
      getAdAccountInsightsByBreakdown("region");
      getAdAccountInsightsByBreakdown("gender");
      getAdAccountInsightsByBreakdown("device_platform");
      getAdAccountInsightsByBreakdown("publisher_platform");
      getFbAdCampaigns();
    }
  }, [adAccountId, filters]);

  useEffect(() => {
    if (adAccountId && adAccountYear) {
      getFbAdInsightsYearly(adAccountYear);
    }
  }, [adAccountYear]);


  const ExportCampaigns=async()=>{
    loader(true)
    const token = await localStorage.getItem("token");
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };
    const req = await axios({
      method: "get",
      url: `${environment.api}api/facebook/campaigns?access_token=${accessToken}&fb_ad_account_id=${adAccountId}&time_range=${JSON.stringify(timeRange)}&download=yes`,
      responseType: "blob",
      body: { token: token },
    });
    let blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Campaigns.xlsx`;
    loader(false)
    link.click();
  }


  const ExportCapiAdset=async()=>{
    loader(true)
    const token = await localStorage.getItem("token");
    const timeRange = {
      'since': filters.startDate,
      'until': filters.endDate,
    };
    const req = await axios({
      method: "get",
      url: `${environment.api}api/facebook/campaigns/adsets?access_token=${accessToken}&campaign_id=${campaignId}&time_range=${JSON.stringify(timeRange)}&download=yes`,
      responseType: "blob",
      body: { token: token },
    });
    let blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Campaigns.xlsx`;
    loader(false)
    link.click();
  }


  
  
  return (
    <div>
      <Layout>
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-col gap-1">
            <h4 className="text-2xl font-semibold text-[#3C3E49]">
              Ad Performance
            </h4>
            <p className="text-sm font-normal text-[#75757A]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit,
              voluptas.
            </p>
          </div>
          <div className="flex gap-2">
            <SelectDropdown
              displayValue="name"
              placeholder="Select Fb Ad Account"
              showPlaceHolder={false}
              options={adAccountsOptions}
              intialValue={adAccountId}
              result={(e) => {
                setAdAccountId(e.value);
              }}
            />
            <DateRangePicker
              disabled={filters.yearType == "calendar" ? true : false}
              title={
                filters.yearType == "calendar"
                  ? ""
                  : "Change Data by Calendar Year to change dates"
              }
              value={{
                startDate: filters.startDate,
                endDate: filters.endDate,
                compare: filters.compare,
                compareStart: filters.compareStart,
                compareEnd: filters.compareEnd,
              }}
              isCompare={true}
              onChange={(e) => onRangeChange(e)}
              fullWidth={false}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-6">
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="Amount Spent" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              ${fbAdsInsights?.data?.[0]?.spend || 0}
            </h1>
            <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
              <HiPlusSmall className="text-[#E92531]" />
              244.9%
            </div>
          </div>
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="Reach" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              {fbAdsInsights?.data?.[0]?.reach || 0}
            </h1>
            <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
              <HiPlusSmall className="text-[#047857]" />
              88.2%
            </div>
          </div>
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="Clicks" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              {fbAdsInsights?.data?.[0]?.clicks || 0}
            </h1>
            <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
              <HiPlusSmall className="text-[#047857]" />
              16.3%
            </div>
          </div>
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="CTR" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              {fbAdsInsights?.data?.[0]?.ctr || 0}%
            </h1>
            <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
              <HiPlusSmall className="text-[#047857]" />
              162.5%
            </div>
          </div>
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="CPC" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              ${fbAdsInsights?.data?.[0]?.cpc || 0}
            </h1>
            <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
              <HiPlusSmall className="text-[#E92531]" />
              52.3%
            </div>
          </div>
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="Impressions" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              {fbAdsInsights?.data?.[0]?.impressions || 0}
            </h1>
            <div className="flex w-fit bg-[#E92531]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#E92531]">
              <HiMinusSmall className="text-[#E92531]" />
              70.2%
            </div>
          </div>
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="Frequency" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              {fbAdsInsights?.data?.[0]?.frequency || 0}
            </h1>
            <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
              <HiPlusSmall className="text-[#047857]" />
              7.1%
            </div>
          </div>
          <div className="p-4 flex flex-col rounded-large bg-white shadow-box border border-grey">
            <Badge label="CPM" />
            <h1 className="text-2xl font-semibold text-[#111827] mb-1 mt-5">
              ${fbAdsInsights?.data?.[0]?.cpm || 0}
            </h1>
            <div className="flex w-fit bg-[#047857]/10 rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
              <HiPlusSmall className="text-[#047857]" />
              3.4%
            </div>
          </div>
        </div>

        <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
          <div className="flex justify-between gap-4 items-center p-5">
            <div className="">
              <h1 className="text-typo text-lg font-semibold">
                Account Overview
              </h1>
              <div className="flex gap-2 items-center">
                <div className="flex w-fit rounded-full gap-0.5 items-center py-0.5 px-1.5 text-xs font-medium text-[#047857]">
                  <IoIosTrendingUp className="text-[#047857]" />
                  2.11%
                </div>
                <p className="text-sm font-medium text-[#6B7280]">
                  Compare to previous month
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <SelectDropdown
                displayValue="name"
                placeholder="Select Year"
                options={years}
                intialValue={adAccountYear}
                result={(e) => {
                  setAdAccountYear(e.value);
                }}
              />
            </div>
          </div>
          <FbLineCurveGraph fbAdInsightsYearly={fbAdInsightsYearly?.data || []} />
        </div>
        <div className="grid xl:grid-cols-2 gap-7 mb-6">
          <div className="shadow-box border border-grey bg-white rounded-large w-full">
            <h1 className="text-typo text-lg font-semibold p-4">Gender</h1>
            <GenderRoundGraph fbAdInsightsByGender={fbAdInsightsByGender?.data || []} />
          </div>
          <div className="shadow-box border border-grey bg-white rounded-large w-full">
            <h1 className="text-typo text-lg font-semibold p-4">Age</h1>
            <AgeLineBarGraph fbAdInsightsByAge={fbAdInsightsByAge?.data || []} />
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-7 mb-6">
          <div className="shadow-box border border-grey bg-white rounded-large w-full">
            <h1 className="text-typo text-lg font-semibold p-4">Device</h1>
            <DeviceRoundGraph fbAdInsightsByDevice={fbAdInsightsByDevice?.data || []} />
          </div>
          <div className="shadow-box border border-grey bg-white rounded-large w-full">
            <h1 className="text-typo text-lg font-semibold p-4">Publish Platform</h1>
            <PublisherPlatformLineBarGraph fbAdInsightsByPublisherPlatform={fbAdInsightsByPublisherPlatform?.data || []} />
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large w-full mb-6">
          <div className="flex justify-between gap-4 items-center p-5">
            <h1 className="text-typo text-lg font-semibold">
              Data Region Wise
            </h1>
          </div>
          <HorizontalLineGraph fbAdInsightsByRegion={fbAdInsightsByRegion?.data || []} />
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Campaigns
            </h1>
            <div>
              <div className="flex gap-4">
                <form className="relative max-w-[250px] w-full">
                  <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
                  <input
                    className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                    placeholder="Search by name"
                  />
                </form>
                <button
                  type="button"
                  onClick={ExportCampaigns}
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead>
                <tr className="w-full">
                  <th className="text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Bid Strategy
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Configured Status
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Created Time
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Daily Budget
                  </th>
                </tr>
              </thead>
              <tbody>
                {fbAdCampaigns.map((campaign) => (
                  <tr key={campaign.id}>
                    <td className="text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                      {campaign.name}
                    </td>
                    <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {campaign.bid_strategy || 'N/A'}
                    </td>
                    <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {campaign.configured_status}
                    </td>
                    <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {new Date(campaign.created_time).toLocaleString()} {/* Format the date */}
                    </td>
                    <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                      {campaign.daily_budget ? `$ ${campaign.daily_budget}` : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Campaigns Insights
            </h1>
            <div>
              <div className="flex gap-4">
                <form className="relative max-w-[250px] w-full">
                  <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
                  <input
                    className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                    placeholder="Search by name"
                  />
                </form>
                <button
                  type="button"
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className="text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Campaign Name
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Impr.
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Reach
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Clicks
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CTR
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CPC
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Objective
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Amount Spent
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(fbAdCampaignInsights).map((campaignId) => {
                  const campaignData = fbAdCampaignInsights[campaignId]?.data;

                  // Only proceed if the data is an array and has elements
                  if (Array.isArray(campaignData) && campaignData.length > 0) {
                    return campaignData.map((item, index) => (
                      <tr key={`${campaignId}-${index}`}>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {item.campaign_name}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {item.impressions}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {item.reach}
                        </td>
                        <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {item.clicks}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {parseFloat(item.ctr).toFixed(2) || 0}%
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                          ${parseFloat(item.cpc).toFixed(2)}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {item.objective || "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 border-r-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          ${parseFloat(item.spend).toFixed(2)}
                        </td>
                      </tr>
                    ));
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">Ad Set</h1>
            <div>
              <div className="flex gap-4">
                <form className="relative max-w-[250px] w-full">
                  <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
                  <input
                    className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                    placeholder="Search by name"
                  />
                </form>
                <button
                  type="button"
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className="text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Ad Set Name
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Daily Budget
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Status
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Destination Type
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Life Time Budget
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Targeting Age
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Targeting Location
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Effective Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(fbCampaignAdSets).map((campaignId) => {
                  const campaignAdSetData = fbCampaignAdSets[campaignId]?.data;

                  // Only proceed if the data is an array and has elements
                  if (Array.isArray(campaignAdSetData) && campaignAdSetData.length > 0) {
                    return campaignAdSetData.map((item, index) => (
                      <tr key={`${campaignId}-${index}`}>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {item.name || "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          ${item.daily_budget || "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {item.status || "N/A"}
                        </td>
                        <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {item.destination_type || "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          ${item.lifetime_budget || "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                          {item.targeting?.age_min && item.targeting?.age_max
                            ? `${item.targeting.age_min} - ${item.targeting.age_max}`
                            : "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          N/A
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 border-r-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {item.effective_status || "N/A"}
                        </td>
                      </tr>
                    ));
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="shadow-box border border-grey bg-white rounded-large mb-6">
          <div className="flex justify-between gap-4 px-5 pt-6 pb-4 items-center">
            <h1 className="text-typo text-lg font-bold">
              Ad Set Insights
            </h1>
            <div>
              <div className="flex gap-4">
                <form className="relative max-w-[250px] w-full">
                  <HiOutlineSearch className="text-[#717275] text-xl shrink-0 absolute top-2.5 left-2" />
                  <input
                    className="w-full outline-none pl-9 p-0 placeholder:text-sm text-typo text-sm placeholder:font-light rounded-large h-10 flex items-center gap-2 px-2 focus:ring-primary focus:ring-2 shadow-box border !border-grey"
                    placeholder="Search by name"
                  />
                </form>
                <button
                  type="button"
                  className="relative cursor-default text-sm rounded-large bg-[#fff] !px-4 text-left focus:outline-none h-10 flex items-center justify-center gap-2 shadow-box border !border-grey hover:bg-[#F3F2F5] transition-all disabled:bg-[#F3F2F5] disabled:cursor-not-allowed"
                >
                  <AiOutlineDownload className="text-typo text-xl" />
                  Download CSV
                </button>
              </div>
            </div>
          </div>
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full">
              <thead className="">
                <tr className="w-full">
                  <th className="text-left cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Ad Set Name
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Impr.
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Reach
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Clicks
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CTR
                  </th>
                  <th className="text-center cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CPC
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-l-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    CPM
                  </th>
                  <th className="cursor-pointer text-[#82838B] border-b-0 border-x-0 font-normal text-sm text-center border border-[#EAECF0] px-3.5 bg-[#F7FAFF] !py-4">
                    Amount Spent
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(fbAdSetInsights).map((adSetId) => {
                  const adSetData = fbAdSetInsights[adSetId]?.data;

                  // Only proceed if the data is an array and has elements
                  if (Array.isArray(adSetData) && adSetData.length > 0) {
                    return adSetData.map((item, index) => (
                      <tr key={`${adSetId}-${index}`}>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-l-0 border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {item.adset_name}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {item.impressions}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {item.reach || "N/A"}
                        </td>
                        <td className="text-center text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border border-[#EAECF0]">
                          {item.clicks || "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          {parseFloat(item.ctr).toFixed(2)}%
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 text-center border border-[#EAECF0]">
                          ${parseFloat(item.cpc).toFixed(2)}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          ${parseFloat(item.cpm).toFixed(2) || "N/A"}
                        </td>
                        <td className="text-typo cursor-pointer px-3.5 text-sm border-b-0 border-r-0 font-normal !py-4 border text-center border-[#EAECF0]">
                          ${parseFloat(item.spend).toFixed(2)}
                        </td>
                      </tr>
                    ));
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
      ;
    </div>
  );
};

export default FacebookAds;
