import React, { useEffect } from 'react'
import ApiClient from '../../../methods/api/apiClient'
import { useNavigate } from 'react-router-dom'
import loader from '../../../methods/loader'
export default function FacebookAdConnection() {
    const Navigate = useNavigate()
    useEffect(() => {
        let url = window.location.hash
        url = url.split('#access_token=')?.[1]?.split('&')?.[0]
        const access_token = url
        if (access_token) {
            loader(true)
            ApiClient.post(`api/connect/fb-ads/account`, { facebook_token: access_token }).then(res => {
                if (res.success) {
                    Navigate('/marketing-analytics')
                }
                loader(false)
            })
        } else {
            Navigate('/marketing-analytics')
        }
    }, [])
    return (
        <div>

        </div>
    )
}
