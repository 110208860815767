import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/global/PageLayout'
import Layout from '../../components/global/layout'
import loader from '../../methods/loader'
import ApiClient from '../../methods/api/apiClient'
import { useSelector } from 'react-redux'
import AllChart from "../../components/common/AllChart";

import environment from '../../environment'
import ForecastChart from './ForeCastChart'
import DemandTable from './DemandTable'
import OverviewCards from './OverViewCards'
import PerformanceChart from './PerformanceChart'
import ProductBreakdownTable from './ProductBreakdownTable'
import TargetsTable from './TargetTable'
import DynamicPricingSection from './DynamicPricingSection'

export default function Forecasting() {
    const CurrentYear = new Date().getFullYear()
    const [filters, setFilters] = useState({})
    const [cardsData, setCardsData] = useState([])
    const [PerformanceChartData, setPerformanceChartData] = useState([]);
    const [PerformanceChartFilter, setPerformanceChartFilter] = useState({ startDate: `${new Date().getFullYear()}-01-01`, endDate: `${new Date().getFullYear()}-12-31`, type: 'monthly' });
    const [ProductBreakdownTableData, setProductBreakdownTableData] = useState({})
    const [ProductBreakdownTableFilter, setProductBreakdownTableFilter] = useState({ productCode: '' })
    const [Loaders, setLoaders] = useState({ cardLoader: false, performanceChartLoader: false })
    const [UniversalProducts, setUniversalProducts] = useState([]);
    const [SingleRecommnededData, setSingleRecommnededData] = useState([]);
    const [SingleRecommendedFilter, setSingleRecommendedFilter] = useState({})
    const [MultipleRecommendedFilter, setMultipleRecommendedFilter] = useState({});
    const [MultipleRecommendedData, setMultipleRecommendedData] = useState([]);
    const [SingleRecommendProducts, setSingleRecommendProducts] = useState([])
    const [MultipleRecommendProducts, setMultipleRecommendProducts] = useState([])


    const [TargetsData, setTargetsData] = useState([]);
    const [TargetTableFilter, setTargetTableFilter] = useState({ year: new Date().getFullYear() });

    const LoaderSet = (key = '', value = true) => {
        setLoaders(prev => ({ ...prev, [key]: value }))
    }
    const user = useSelector(state => state.user);

    //  FUNCTION 1
    const GetCardsData = (p = {}) => {
        const flt = { ...filters, ...p }
        LoaderSet('cardLoader', true)
        ApiClient.get(`api/forecasting/count`, flt).then((response) => {
            if (response.success) {
                setCardsData(response)
            }
            LoaderSet('cardLoader', false)
        })
    }

    //  FUNCTION 2
    const GetPerformanceGraphData = (p = {}) => {
        const flt = { ...filters, ...PerformanceChartFilter, ...p }
        setPerformanceChartFilter({ ...flt })
        LoaderSet('performanceChartLoader', true)
        ApiClient.get(`api/forecasting/graph`, flt).then((response) => {
            if (response.success) {
                setPerformanceChartData(response)
            }
            LoaderSet('performanceChartLoader', false)

        })

    }

    //  FUNCTION 3
    const GetProductBreakdownTable = (p = {}) => {
        const flt = { ...filters, ...ProductBreakdownTableFilter, ...p }
        setProductBreakdownTableFilter({ ...flt })
        LoaderSet('breakdownTable', true)
        ApiClient.get(`api/forecasting/breakdown/product`, flt).then((response) => {
            if (response.success) {
                setProductBreakdownTableData(response)
            }
            LoaderSet('breakdownTable', false)
        })
    }

    //  FUNCTION 4
    const GetTargetsData = (p = {}) => {
        const flt = { ...filters, ...TargetTableFilter, ...p }
        setTargetTableFilter({ ...flt })
        LoaderSet('targetLoader', true)
        ApiClient.get(`api/forecasting/target/by-product`, flt).then((response) => {
            if (response.success) {
                setTargetsData(response?.data)
            }
            LoaderSet('targetLoader', false)

        })
    }


    //  FUNCTION for 6 Table 
    const GetRecommended = (p = {}, type = 'single') => {
        let flt = { ...filters, ...SingleRecommendedFilter, ...p }
        if (type === 'multiple') {
            flt = { ...filters, ...MultipleRecommendedFilter, ...p }
            setMultipleRecommendedFilter({ ...flt })
        } else {
            setSingleRecommendedFilter({ ...flt })
        }

        LoaderSet(`${type}Recommend`, true)
        ApiClient.get(`api/forecasting/breakdown/product`, flt).then((response) => {
            if (response.success) {
                if (type === 'single') {
                    setSingleRecommnededData(response)
                } else {
                    setMultipleRecommendedData(response)
                }
            }
            LoaderSet(`${type}Recommend`, false)
        })
    }

    const AllFunctionCaller = (p = {}) => {
        // GetPredictionsData(p)
        GetTargetsData(p)
        GetCardsData(p)
        GetPerformanceGraphData(p)
        GetProductBreakdownTable(p)
    }


    const GetUniversalProducts = () => {
        loader(true);
        ApiClient.get(`api/v2/products`, { third_party_delete: false, addedBy: user?.supplier_id?.id || user?.supplier_id?._id || user?.id || user?._id }).then(res => {
            if (res.success) {
                if (res?.data[0]) {
                    AllFunctionCaller({ productCode: res.data[0]?.productCode })
                } else {
                    AllFunctionCaller()
                }
                setUniversalProducts(res.data?.map((item) => ({ ...item, id: item?.productCode })))
            }
            loader(false)
        })

    }

    const GetRecommendProducts = (type = 'single') => {
        let url = 'api/day/tours';
        if (type !== 'single') {
            url = 'api/tous/multiple/times'
        }
        ApiClient.get(url).then(async (res) => {
            if (res.success) {
                let data = []
                await res.data?.map((item) => {
                    let product = data.find((itm) => itm.id === item.productCode)
                    if (!product) {
                        data.push({ ...item, id: item?.productCode, name: item?.productName })
                    }
                })
                if (data.length > 0) {
                    GetRecommended({ productCode: data[0]?.id }, type)
                }
                if (type === 'single') {
                    setSingleRecommendProducts(data);
                } else {
                    setMultipleRecommendProducts(data);
                }
            }
        })


    }
    useEffect(() => {
        // AllFunctionCaller();
        GetRecommendProducts('single');
        GetRecommendProducts('multiple');
        GetUniversalProducts();
    }, [])
    return (
        <div>
            <Layout>
                <div className=" mb-6">
                    <div className="flex flex-col gap-1 mb-4 border-b border-gray-200 pb-4">
                        <h4 className="text-2xl font-semibold text-[#3C3E49]">
                            ForeCasting
                        </h4>
                        <p className="text-sm font-normal text-[#75757A]">
                            Comprehensive Sales Performance and Forecasting
                        </p>
                    </div>

                    <div className="space-y-6">
                        {/* Overview Cards */}
                        <OverviewCards UniversalProducts={UniversalProducts} cardsData={cardsData} />

                        {/* Performance Report */}
                        <div className="bg-white rounded-lg shadow mt-6">

                            <PerformanceChart getData={GetPerformanceGraphData} filter={PerformanceChartFilter} setfilter={setPerformanceChartFilter} UniversalProducts={UniversalProducts} ResponseData={PerformanceChartData} Loaders={Loaders} />
                        </div>

                        {/* Forecasting Breakdown */}
                        <div className="bg-white  rounded-lg shadow mt-6">
                            <ProductBreakdownTable getData={GetProductBreakdownTable} Loaders={Loaders} setfilter={setProductBreakdownTableFilter} filter={ProductBreakdownTableFilter} UniversalProducts={UniversalProducts} ResponseData={ProductBreakdownTableData} />
                        </div>

                        {/* Targets Table */}
                        <div className="bg-white rounded-lg shadow mt-6">

                            <TargetsTable getData={GetTargetsData} Loaders={Loaders} Responsedata={TargetsData} filter={TargetTableFilter} setfilter={setTargetTableFilter} UniversalProducts={UniversalProducts} />
                        </div>

                        {/* Dynamic Pricing Section */}
                        <div className="bg-white rounded-lg shadow mt-6">

                            <DynamicPricingSection UniversalProducts={UniversalProducts} />
                        </div>



                        <div className="bg-white rounded-lg shadow mt-6">
                            <ProductBreakdownTable key={'multiple'} type='multiple' title='Recomended Dynamic Pricing-tours with multiple times' getData={GetRecommended} Loaders={{ ...Loaders, breakdownTable: Loaders?.multipleRecommend }} setfilter={setMultipleRecommendedFilter} filter={MultipleRecommendedFilter} UniversalProducts={MultipleRecommendProducts} ResponseData={MultipleRecommendedData} />
                        </div>

                        <div className="bg-white rounded-lg shadow mt-6">
                            <ProductBreakdownTable type='single' key={'single'} title='Recomended Dynamic Pricing - Day Tours' getData={GetRecommended} Loaders={{ ...Loaders, breakdownTable: Loaders?.singleRecommend }} setfilter={setSingleRecommendedFilter} filter={SingleRecommendedFilter} UniversalProducts={SingleRecommendProducts} ResponseData={SingleRecommnededData} />
                        </div>

                        {/* dyanmic pricing - day tours */}



                    </div>
                </div>
            </Layout>
        </div>
    )
}
