import { useEffect, useState } from "react"
import SelectDropdown from "../SelectDropdown"
import LineChart from "../LineChart";
import BarChart from "../BarChart";
import PieChart from "../PieChart";

export default ({ data = [], legends = [], showReply = false, refundData =false,showSaleFormat=false})=>{
    const [type,setType]=useState('Line')

    const options=[
        {id:'Line',name:'Line Chart'},
        {id:'Bar',name:'Bar Chart'},
    ]

    useEffect(()=>{

    },[])
    
    return <>
    <div className="">
        <div className="flex mb-3">
        <div className="ml-auto">
        <SelectDropdown
            displayValue="name"
            placeholder="Select Chart"
            options={options}
            intialValue={type}
            result={e=>setType(e.value)}
            noDefault={true}
            />
        </div>
        </div>
        {type=='Line'?<>
                <LineChart data={data} showReply={showReply} legends={legends} showSaleFormat={showSaleFormat} refundData={refundData}/>
        </>:<></>}

        {type=='Bar'?<>
                <BarChart data={data} legends={legends} showReply={showReply} showSaleFormat={showSaleFormat} refundData={refundData} />
        </>:<></>}
        {type=='Pie'?<>
        <PieChart data={data} legends={legends} />
        </>:<></>}
        
    </div>
    </>
}