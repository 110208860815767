// src/components/ForecastChart.js
import React from 'react';
import ReactECharts from 'echarts-for-react';

const ForecastChart = () => {
    const option = {
        title: {
            text: 'Forecast vs Actuals',
        },
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            data: ['Actuals', 'Forecast', 'Targets'],
        },
        xAxis: {
            type: 'category',
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: 'Actuals',
                type: 'bar',
                data: [120, 200, 150, 80, 70],
            },
            {
                name: 'Forecast',
                type: 'line',
                data: [180, 250, 220, 130, 190],
            },
            {
                name: 'Targets',
                type: 'line',
                data: [160, 230, 210, 110, 170],
            },
        ],
    };

    return <ReactECharts option={option} style={{ height: 400 }} />;
};

export default ForecastChart;
