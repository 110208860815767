import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../../components/Chart";

const EngagementVerticalBar = ({ colors = ["#758df0", "#95e4ac"] }) => {
  const user = useSelector((state) => state.user);

  const chartData = {
    Sun: { Engagement: 11000, Views: 22000 },
    Mon: { Engagement: 9000, Views: 18000 },
    Tue: { Engagement: 10000, Views: 19000 },
    Wed: { Engagement: 11000, Views: 21000 },
    Thu: { Engagement: 9000, Views: 20000 },
    Fri: { Engagement: 7000, Views: 17000 },
    Sat: { Engagement: 8000, Views: 29000 },
  };

  const days = Object.keys(chartData);

  const updatedOption = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        const { seriesName, value, color } = params;

        return `
          <div style="display: flex; align-items: center;">
            <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${color}; margin-right: 5px;"></span>
            <p style="font-size:14px;">${seriesName}</p>
          </div>
          <div>Value: <b> ${value.toLocaleString()} </b></div>
        `;
      },
    },
    legend: {
      data: ["Engagement", "Video views"],
    },
    xAxis: {
      type: "category",
      data: days,
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 12,
        fontWeight: 500,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: 12,
        fontWeight: 500,
        formatter: (value) => {
          if (value === 0) return '0';
          if (value === 15000) return '15k';  
          if (value === 30000) return '30k';  
          return ''; 
        },
      },
    },
    series: [
      {
        name: "Engagement",
        type: "bar",
        label: {
          show: false,
        },
        data: days.map((day) => chartData[day].Engagement),
        itemStyle: {
          color: colors[0],
          borderRadius: [4, 4, 0, 0],
        },
        barWidth: "15%",
        barGap: "0%",
      },
      {
        name: "Video views",
        type: "bar",
        label: {
          show: false,
        },
        data: days.map((day) => chartData[day].Views),
        itemStyle: {
          color: colors[1],
          borderRadius: [4, 4, 0, 0],
        },
        barWidth: "15%",
        barGap: "0%",
      },
    ],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
      width: '95%',
    },
  };

  return (
    <div className="px-[20px] pb-[20px]">
      <Chart options={updatedOption} />
    </div>
  );
};

export default EngagementVerticalBar;
